  import React, { useState } from 'react';
  import { NavLink, useNavigate } from 'react-router-dom';
  import { 
    LayoutDashboard, 
    PlusCircle, 
    BarChart2, 
    Settings, 
    Users, 
    HelpCircle, 
    LogOut, 
    Sparkles,
    Book
  } from 'lucide-react';

  const Sidebar = ({open = false, setOpen}) => {
    const navigate = useNavigate();
    const [hoveredItem, setHoveredItem] = useState(null);

    const handleLogout = () => {
      localStorage.removeItem('user');
      navigate('/');
    };

    const navItems = [
      { icon: <LayoutDashboard size={20} />, text: 'Dashboard', path: '/dashboard' },
      { icon: <PlusCircle size={20} />, text: 'Create Quiz', path: '/createquiz' },
      { icon: <BarChart2 size={20} />, text: 'Polling', path: '/polling' },
      { icon: <Users size={20} />, text: 'Participants', path: '/quiz/:quizId/participants' },
      { icon: <Settings size={20} />, text: 'Settings', path: '/settings' },
    ];

    return (
      <>
        <div 
          className={`
            fixed inset-y-0 left-0 w-64
            bg-[#1E293B]
            pt-16 pb-4 flex flex-col 
            transition-all duration-500 ease-out
            ${open ? 'translate-x-0' : '-translate-x-full'}
            md:translate-x-0 md:block
            z-[99]
            shadow-xl
            animate-fadeIn
          `}
        >
          {/* Animated Logo */}
          <div className="absolute top-4 left-6 flex items-center gap-3">                                     
            <div className="relative w-10 h-10 bg-gradient-to-tr from-emerald-400 via-teal-400 to-cyan-400 
                          rounded-xl flex items-center justify-center group overflow-hidden
                          animate-logoFloat">
              <Book className="w-6 h-6 text-white transform transition-all duration-300 
                            group-hover:scale-125 group-hover:rotate-12" />
              <div className="absolute inset-0 bg-white opacity-0 group-hover:opacity-20 
                            transition-opacity duration-300" />
              {/* Rotating Border Effect */}
              <div className="absolute -inset-1 bg-gradient-to-r from-emerald-400 via-cyan-400 to-emerald-400 
                            opacity-30 group-hover:opacity-50 blur-sm animate-spin-slow" />
            </div>
            <span className="text-white font-bold text-xl tracking-tight relative">
              QuizPro
              <span className="absolute -top-1 -right-4">
                <Sparkles className="w-4 h-4 text-emerald-400 animate-twinkle" />
              </span>
            </span>
          </div>

          {/* Navigation Items with Enhanced Animations */}
          <nav className="mt-12 flex-1 px-4 space-y-1">
            {navItems.map((item, index) => (
              <NavLink
                key={item.path}
                to={item.path}
                onMouseEnter={() => setHoveredItem(item.path)}
                onMouseLeave={() => setHoveredItem(null)}
                onClick={() => setOpen(false)}
                className={({ isActive }) =>
                  `flex items-center gap-4 px-4 py-3
                  text-sm font-medium rounded-xl
                  transition-all duration-300 ease-out
                  relative overflow-hidden
                  animate-slideIn
                  hover:bg-white/10
                  ${isActive 
                    ? 'text-white bg-gradient-to-r from-emerald-400 to-cyan-400 shadow-lg shadow-emerald-400/20'
                    : 'text-gray-300 hover:text-white'
                  }
                  group
                  `}
                style={{
                  animationDelay: `${index * 100}ms`
                }}
              >
                <div className="relative z-10 transition-all duration-300 
                              group-hover:scale-110 group-hover:rotate-6">
                  {item.icon}
                </div>
                <span className="relative z-10 transition-transform duration-300 
                              group-hover:translate-x-1">{item.text}</span>
                
                {/* Animated Background */}
                <div className="absolute inset-0 bg-gradient-to-r from-emerald-400/20 to-cyan-400/20 
                              opacity-0 group-hover:opacity-100 transition-all duration-300
                              translate-x-[-100%] group-hover:translate-x-0" />
                
                {/* Particle Effects on Hover */}
                {hoveredItem === item.path && (
                  <div className="absolute inset-0 pointer-events-none">
                    {[...Array(3)].map((_, i) => (
                      <div
                        key={i}
                        className="absolute w-1 h-1 bg-emerald-400 rounded-full
                                animate-particle opacity-75"
                        style={{
                          left: `${Math.random() * 100}%`,
                          top: `${Math.random() * 100}%`,
                          animationDelay: `${i * 200}ms`
                        }}
                      />
                    ))}
                  </div>
                )}
              </NavLink>
            ))}
          </nav>

          {/* Animated Action Buttons */}
          <div className="px-4 space-y-2 mt-6">
            <button 
            disabled={true}
              className="flex items-center gap-4 px-4 py-3 w-full
                      text-sm font-medium text-gray-300
                      hover:text-white hover:bg-white/10 rounded-xl
                      transition-all duration-300
                      group transform hover:translate-x-1"
            >
              <HelpCircle className="transition-all duration-300 group-hover:scale-110 group-hover:rotate-12" />
              <span>Help & Support</span>
            </button>

            <button
              onClick={handleLogout}
              className="flex items-center gap-4 px-4 py-3 w-full
                      text-sm font-medium text-red-400
                      hover:bg-red-400/10 hover:text-red-300 rounded-xl
                      transition-all duration-300
                      group transform hover:translate-x-1
                      relative overflow-hidden"
            >
              <LogOut className="transition-all duration-300 group-hover:scale-110 group-hover:-rotate-12" />
              <span>Logout</span>
              <div className="absolute inset-0 bg-gradient-to-r from-red-400/0 via-red-400/10 to-red-400/0
                          opacity-0 group-hover:opacity-100 
                          -translate-x-full group-hover:translate-x-full
                          transition-all duration-1000" />
            </button>
          </div>

          {/* Animated Background Pattern */}
          <div className="absolute inset-0 opacity-[0.03] pointer-events-none animate-backgroundPulse">
            <div className="absolute inset-0" style={{
              backgroundImage: `radial-gradient(circle at 1px 1px, currentColor 1px, transparent 0)`,
              backgroundSize: '20px 20px'
            }} />
          </div>
        </div>

        {/* Animated Mobile Backdrop */}
        {open && (
          <div 
            onClick={() => setOpen(false)}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm
                      animate-fadeIn
                      transition-opacity duration-300 ease-in-out z-30 md:hidden"
          />
        )}

        <style jsx>{`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          @keyframes slideIn {
            from { transform: translateX(-100%); opacity: 0; }
            to { transform: translateX(0); opacity: 1; }
          }
          @keyframes logoFloat {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-4px); }
          }
          @keyframes particle {
            0% { transform: translate(0, 0) scale(0); opacity: 1; }
            100% { transform: translate(50px, -50px) scale(2); opacity: 0; }
          }
          @keyframes backgroundPulse {
            0%, 100% { opacity: 0.03; }
            50% { opacity: 0.05; }
          }
          @keyframes spin-slow {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
          @keyframes twinkle {
            0%, 100% { opacity: 1; transform: scale(1); }
            50% { opacity: 0.5; transform: scale(0.8); }
          }
          .animate-fadeIn {
            animation: fadeIn 0.5s ease-out;
          }
          .animate-slideIn {
            animation: slideIn 0.5s ease-out forwards;
          }
          .animate-logoFloat {
            animation: logoFloat 3s ease-in-out infinite;
          }
          .animate-particle {
            animation: particle 1s ease-out forwards;
          }
          .animate-backgroundPulse {
            animation: backgroundPulse 4s ease-in-out infinite;
          }
          .animate-spin-slow {
            animation: spin-slow 8s linear infinite;
          }
          .animate-twinkle {
            animation: twinkle 1.5s ease-in-out infinite;
          }
        `}</style>
      </>
    );
  };

  export default Sidebar;