import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { 
  LucideBadgePlus, 
  LucideBadgeMinus, 
  Send, 
  PlusCircle, 
  XCircle, 
  Archive,
  Sparkles,
  Layers,
  TrendingUp,
  Settings,
  Clock,
  History
} from 'lucide-react';

import api from '../config/axious';
import ActivePolls from './ActivePolls';
import { useNavigate, useParams } from 'react-router-dom';
import confetti from 'canvas-confetti';
import { toast } from 'sonner';

function PollingApp() {
   const navigate=useNavigate()
  const [userId, setUserId] = useState(null);
  const [pollQuestion, setPollQuestion] = useState('');
  const [refCount, setRefCount] = useState(0)
  const [pollOptions, setPollOptions] = useState(['', '']);
  const [pollStatistics, setPollStatistics] = useState({
    totalPolls: 0,
    activePollsCount: 0,
    completedCount: 0
  });
  const [errorMessage, setErrorMessage] = useState('');
  
  // New state for time limit
  const [timeLimit, setTimeLimit] = useState({
    days: 0,
    hours: 0,
    minutes: 0
  });

  useEffect(() => {
    const ls = JSON.parse(localStorage.getItem('user'))
    setUserId(ls.id)
    if(userId) {
      fetchPollStatistics();
    }
  }, [userId,refCount]);

  // Existing functions remain the same
  const handleAddOption = () => {
    if (pollOptions.length < 5) {
      setPollOptions([...pollOptions, '']);
    }
  };

  const handleRemoveOption = (index) => {
    if (pollOptions.length > 2) {
      const newOptions = [...pollOptions];
      newOptions.splice(index, 1);
      setPollOptions(newOptions);
    }
  };

  // Modified create poll function to include end time
  const handleCreatePoll = async () => {
    if (!pollQuestion || pollOptions.some(opt => !opt.trim())) {
      setErrorMessage('Please fill in all options and question');
      return;
    }

  

    const totalMinutes = 
    (timeLimit.days * 24 * 60) + 
    (timeLimit.hours * 60) + 
    parseInt(timeLimit.minutes);
    console.log('Total Minutes:', totalMinutes);
  if (totalMinutes <= 0) {
    setErrorMessage('Please set a valid time limit');
    return;
  }
  
  // Calculate end time
  const now = moment();
  const endTime = now.add(totalMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss');
  
  // console.log('Local Time:', endTime);
    try {
      const response = await api.post('/api/create-polls', {
        question: pollQuestion,
        options: pollOptions,
        userId: userId,
        endTime: endTime
      });
        setRefCount((prev)=>(prev+1))
      // Reset form
      toast.success("Poll created")
      setPollQuestion('');
      setPollOptions(['', '']);
      setTimeLimit({ days: 0, hours: 0, minutes: 0 });
      setErrorMessage('');
      confetti({
              particleCount: 100,
              spread: 70,
              origin: { y: 0.6 }
            });
    } catch (error) {
      console.error("Error creating poll:", error);
      setErrorMessage('Failed to create poll. Please try again.');
    }
  };

  const fetchPollStatistics = async () => {
    try {
      const response = await api.get(`/api/poll-counts/${userId}`);
      if (response.data.success) {
        const { total_polls, active_polls, completed_polls } = response.data.data;
        setPollStatistics({
          totalPolls: total_polls || 0,
          activePollsCount: active_polls || 0,
          completedCount: completed_polls || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching poll statistics:", error.message);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-purple-100 p-6 selection:bg-purple-200">
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Elegant Header */}
        <div className="bg-white shadow-2xl rounded-2xl p-2 text-center border-b-4 border-indigo-500">
          <div className="flex justify-center items-center mb-4">
            <Sparkles className="text-purple-600 mr-3 w-10 h-10" />
            <h1 className="text-4xl font-black bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-indigo-600">
              Polling Nexus
            </h1>
          </div>
          <p className="text-md text-gray-600 max-w-xl mx-auto">
            Unleash the power of collective insights with real-time, interactive polling
          </p>
        </div>

        {/* Enhanced Statistics Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[
            { 
              icon: Layers, 
              color: 'indigo', 
              label: 'Total Polls', 
              value: pollStatistics.totalPolls 
            },
            { 
              icon: TrendingUp, 
              color: 'green', 
              label: 'Active Polls', 
              value: pollStatistics.activePollsCount 
            },
            { 
              icon: Archive, 
              color: 'purple', 
              label: 'Completed Polls', 
              value: pollStatistics.completedCount 
            }
          ].map(({ icon: Icon, color, label, value }, index) => (
            <div 
              key={index} 
              className={`bg-white rounded-2xl shadow-xl p-2 text-center transform transition hover:-translate-y-2 hover:shadow-2xl`}
            >
              <Icon className={`mx-auto mb-4 text-${color}-600 w-6 h-6`} />
              <h3 className={`text-sm font-semibold text-${color}-700 mb-2`}>{label}</h3>
              <p className={`text-xl font-bold text-${color}-500`}>{value}</p>
            </div>
          ))}
        </div>


        <div className=" space-x-4 mb-6 text-end">
         <button className='bg-[#4f46e5] rounded-lg border  p-3 inline-flex items-center' onClick={()=>{navigate('/polling_history')}}>
          
           <History className="text-indigo-600 w-8 h-8 " color='white' />
            <h2 className="text-xl ms-0.5 font-bold text-white">
             Poll History
            </h2>
          </button>  
          </div>
 

        {/* Poll Creation Section with Enhanced Design */}
        <div className="bg-white shadow-2xl rounded-2xl p-3 px-8 space-y-6 border-l-4 border-indigo-500">
        


          <div className="flex items-center space-x-4 mb-6">
            <Settings className="text-indigo-600 w-8 h-8 " />
            <h2 className="text-xl font-bold text-indigo-800">
              Craft Your Poll
            </h2>
          </div>
          <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <Clock className="text-indigo-600 w-5 h-5" />
            <h3 className="text-lg font-semibold text-indigo-800">Set Time Limit</h3>
          </div>
          
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Days</label>
              <input
                type="number"
                min="0"
                value={timeLimit.days}
                onChange={(e) => setTimeLimit({...timeLimit, days: parseInt(e.target.value) || 0})}
                className="w-full p-2 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-400"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Hours</label>
              <input
                type="number"
                min="0"
                max="23"
                value={timeLimit.hours}
                onChange={(e) => setTimeLimit({...timeLimit, hours: parseInt(e.target.value) || 0})}
                className="w-full p-2 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-400"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Minutes</label>
              <input
                type="number"
                min="0"
                max="59"
                value={timeLimit.minutes}
                onChange={(e) => setTimeLimit({...timeLimit, minutes: parseInt(e.target.value) || 0})}
                className="w-full p-2 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-400"
              />
            </div>
          </div>
        </div>
          <input 
            type="text"
            placeholder="What fascinating question do you want to ask?"
            value={pollQuestion}
            onChange={(e) => setPollQuestion(e.target.value)}
            className="w-full p-4 border-2 border-indigo-200 rounded-xl focus:ring-4 focus:ring-indigo-300 transition duration-300 text-gray-700 placeholder-gray-400"
          />

          {pollOptions.map((option, index) => (
            <div key={index} className="flex space-x-3 items-center">
              <input 
                type="text"
                placeholder={`Option ${index + 1}`}
                value={option}
                onChange={(e) => {
                  const newOptions = [...pollOptions];
                  newOptions[index] = e.target.value;
                  setPollOptions(newOptions);
                }}
                className="flex-grow p-2 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-indigo-400 transition duration-300"
              />
              {pollOptions.length > 2 && (
                <button 
                  onClick={() => handleRemoveOption(index)}
                  className="text-red-500 hover:bg-red-100 p-2 rounded-full transition"
                >
                  <XCircle className="w-6 h-6" />
                </button>
              )}
            </div>
          ))}

          <div className="flex justify-between items-center">
            {pollOptions.length < 5 && (
              <button 
                onClick={handleAddOption}
                className="flex items-center bg-green-500 text-white px-3 py-2 rounded-lg hover:bg-green-600 transition space-x-2"
              >
                <LucideBadgePlus /> 
                <span className='text-sm'>Add Option</span>
              </button>
            )}
            <button 
              onClick={handleCreatePoll}
              className="flex items-center bg-indigo-600 text-white px-3 py-3 rounded-lg hover:bg-indigo-700 transition space-x-2 shadow-lg hover:shadow-xl"
            >
              <Send className="w-3 h-3" /> 
              <span className='text-sm'>Create Poll</span>
            </button>
          </div>

          {errorMessage && (
            <div className="bg-red-50 border-l-4 border-red-500 p-4 text-red-700 rounded-r-lg">
              {errorMessage}
            </div>
          )}
        </div>

        {/* Active Polls Section */}
        <ActivePolls refresh={refCount} />
      </div>
    </div>
  );
}

export default PollingApp;