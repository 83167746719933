// import React, { useState, useEffect } from 'react';
// import { BarChart,ResponsiveContainer, Bar, XAxis, Cell,YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import io from 'socket.io-client';
// import { useParams } from 'react-router-dom';
// import api from '../config/axious';

// const PollChart = () => {
//   const [chartData, setChartData] = useState([]);
//   const [socket, setSocket] = useState(null);
//   const [question,setQuestion]=useState("")
//   const { shareToken } = useParams();
//   const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300'];
//   useEffect(() => {
//     const newSocket = io('http://localhost:4000');
//     setSocket(newSocket);

    
//     newSocket.emit('join', shareToken);

   
//     newSocket.on('poll-update', (data) => {
//       console.log(data);
//       const transformedData = data[0]?.options.map((option, index) => ({
//         option: option,
//         voteCount: data[0].voteCounts[index]
//       }));
//       setChartData(transformedData);
//     });

//     return () => {
//       newSocket.disconnect();
//     };
//   }, [shareToken]);


//   useEffect(() => {

//     const fetchPollStatistics = async () => {
//       try {
//         const response = await api.get(`/api/chartsstats/${shareToken}`);
        
//         // Transform the data structure
//         setQuestion(response.data?.question)
//         const transformedData = response.data?.voteData[0]?.options.map((option, index) => ({
//           option: option,
//           voteCount: response.data.voteData[0].voteCounts[index]
//         }));
  
//         setChartData(transformedData);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     fetchPollStatistics();
//   }, [shareToken]);

//   return (


//     <div className="max-w-4xl h-[400px] min-h-[300px] px-2 sm:px-0 mx-auto">
//       <h2 className='text-2xl font-semibold text-center'>{question}</h2>
//     <ResponsiveContainer width="100%" height="100%">
//       <BarChart
//         data={chartData}
//         margin={{
//           top: 20,
//           right: 10,
//           left: 0,
//           bottom: 5,
//         }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis 
//           dataKey="option"
//           tick={{ fontSize: 12 }}
//           interval={0}
//           angle={-45}
//           textAnchor="end"
//           height={60}
//         />
//         <YAxis 
//           domain={[0, 'dataMax']}
//           tick={{ fontSize: 12 }}
//         />
//         <Tooltip />
//         <Legend 
//           wrapperStyle={{
//             fontSize: '12px',
//             paddingTop: '10px'
//           }}
//         />
//         <Bar 
//           dataKey="voteCount" 
//           name="Votes"
//           label={{ 
//             position: 'top',
//             fontSize: 12,
//             fill: '#666',
//           }}
//         >
//           {chartData.map((entry, index) => (
//             <Cell 
//               key={`cell-${index}`} 
//               fill={colors[index % colors.length]}
//             />
//           ))}
//         </Bar>
//       </BarChart>
//     </ResponsiveContainer>
//   </div>



//   );
// };

// export default PollChart;


import React, { useState, useEffect } from 'react';
import { BarChart, ResponsiveContainer, Bar, XAxis, Cell, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import io from 'socket.io-client';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import api from '../config/axious';

const PollChart = () => {
  const [chartData, setChartData] = useState([]);
  const [socket, setSocket] = useState(null);
  const [question, setQuestion] = useState("");
  const { shareToken } = useParams();
  const navigate = useNavigate();
  
  const colors = [
    '#6366F1', // Indigo-500
    '#10B981', // Emerald-500
    '#F59E0B', // Amber-500
    '#EC4899', // Pink-500
    '#8B5CF6', // Purple-500
    '#14B8A6'  // Teal-500
  ];

  useEffect(() => {
    const newSocket = io('https://app.quizzbuddy.com/');
    setSocket(newSocket);
    
    newSocket.emit('join', shareToken);
    
    newSocket.on('poll-update', (data) => {
      console.log(data);
      const transformedData = data[0]?.options.map((option, index) => ({
        option: option,
        voteCount: data[0].voteCounts[index]
      }));
      setChartData(transformedData);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [shareToken]);

  useEffect(() => {
    const fetchPollStatistics = async () => {
      try {
        const response = await api.get(`/api/chartsstats/${shareToken}`);
        setQuestion(response.data?.question);
        const transformedData = response.data?.voteData[0]?.options.map((option, index) => ({
          option: option,
          voteCount: response.data.voteData[0].voteCounts[index]
        }));
        setChartData(transformedData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPollStatistics();
  }, [shareToken]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="max-w-4xl mt-3 mx-auto p-6 bg-white rounded-lg shadow-lg">
      <div className="mb-6 ">
        <button 
          onClick={handleBack}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back
        </button>
      </div>
      
      <div className="space-y-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          {question}
        </h2>
        
        <div className="h-96 w-full bg-white rounded-xl p-4">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 60,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
              <XAxis
                dataKey="option"
                tick={{ fontSize: 12, fill: '#4B5563' }}
                interval={0}
                angle={-45}
                textAnchor="end"
                height={60}
              />
              <YAxis
                domain={[0, 'dataMax + 2']}
                tick={{ fontSize: 12, fill: '#4B5563' }}
              />
              <Tooltip 
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  border: 'none',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                }}
              />
              <Legend
                wrapperStyle={{
                  fontSize: '14px',
                  paddingTop: '20px'
                }}
              />
              <Bar
                dataKey="voteCount"
                name="Votes"
                label={{
                  position: 'top',
                  fontSize: 12,
                  fill: '#6B7280',
                  fontWeight: 500,
                }}
                radius={[4, 4, 0, 0]}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                    className="hover:opacity-80 transition-opacity duration-200"
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default PollChart;