import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { User, Lock, Save, Gamepad2, Puzzle, Sun, Moon, Trophy } from 'lucide-react';
import confetti from 'canvas-confetti';

const QuizBubble = ({ question, answer, x, y, theme }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);

  const handleClick = () => {
    if (!isAnswered) {
      setIsFlipped(!isFlipped);
      if (isFlipped) {
        setIsAnswered(true);
      }
    }
  };

  return (
    <motion.div
      className="absolute cursor-pointer"
      style={{ left: `${x}%`, top: `${y}%` }}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={handleClick}
    >
      <motion.div
        className={`w-20 h-20 rounded-full flex items-center justify-center text-center p-2 text-xs ${
          isAnswered ? 'bg-green-400' : 'bg-yellow-400'
        } ${theme === 'light' ? 'text-gray-800' : 'text-white'} font-semibold shadow-lg`}
        animate={{ rotateY: isFlipped ? 180 : 0 }}
        transition={{ duration: 0.6 }}
      >
        <div style={{ backfaceVisibility: 'hidden' }}>
          {isFlipped ? answer : question}
        </div>
      </motion.div>
    </motion.div>
  );
};

const QuizGameSettingsPage = () => {
  const [updateType, setUpdateType] = useState(null);
  const [newUsername, setNewUsername] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState({ type: '', content: '' });
  const [theme, setTheme] = useState('light');
  const [quizBubbles, setQuizBubbles] = useState([]);

  const quizData = [
    { question: "2 + 2 = ?", answer: "4" },
    { question: "Capital of France?", answer: "Paris" },
    { question: "Largest mammal?", answer: "Blue Whale" },
    { question: "H2O is?", answer: "Water" },
    { question: "Planets in our solar system?", answer: "8" },
  ];

  useEffect(() => {
    document.body.className = theme;
    generateQuizBubbles();
  }, [theme]);

  const generateQuizBubbles = () => {
    const newBubbles = quizData.map((quiz, index) => ({
      ...quiz,
      x: Math.random() * 80 + 10,
      y: Math.random() * 80 + 10,
    }));
    setQuizBubbles(newBubbles);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setMessage({ type: '', content: '' });

    try {
      const token = localStorage.getItem('token');
      const userId = JSON.parse(localStorage.getItem('user')).id;

      if (updateType === 'username') {
        if (!newUsername.trim()) {
          setMessage({ type: 'error', content: 'Username cannot be empty' });
          return;
        }

        const response = await fetch('http://localhost:4000/api/settings/username', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            userId,
            newUsername: newUsername.trim()
          })
        });

        const data = await response.json();
        if (data.success) {
          const user = JSON.parse(localStorage.getItem('user'));
          user.user_name = newUsername;
          localStorage.setItem('user', JSON.stringify(user));
          setMessage({ type: 'success', content: 'Username updated successfully! 🎉' });
          setNewUsername('');
          launchConfetti();
        } else {
          setMessage({ type: 'error', content: data.message });
        }
      } else if (updateType === 'password') {
        if (!currentPassword || !newPassword || !confirmPassword) {
          setMessage({ type: 'error', content: 'All password fields are required 🔑' });
          return;
        }

        if (newPassword !== confirmPassword) {
          setMessage({ type: 'error', content: 'New passwords do not match 🚫' });
          return;
        }

        if (newPassword.length < 6) {
          setMessage({ type: 'error', content: 'Password must be at least 6 characters long 📏' });
          return;
        }

        const response = await fetch('http://localhost:4000/api/settings/password', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            userId,
            currentPassword,
            newPassword
          })
        });

        const data = await response.json();
        if (data.success) {
          setMessage({ type: 'success', content: 'Password updated successfully! 🔐' });
          setCurrentPassword('');
          setNewPassword('');
          setConfirmPassword('');
          launchConfetti();
        } else {
          setMessage({ type: 'error', content: data.message });
        }
      }
    } catch (error) {
      setMessage({ type: 'error', content: 'An error occurred. Please try again. 🎮' });
    }
  };

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const launchConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      colors: ['#FFA500', '#FFD700', '#FF6347', '#00CED1', '#FF1493'],
    });
  };

  return (
    <div className={`min-h-screen overflow-hidden relative ${
      theme === 'light' 
        ? 'bg-gradient-to-br from-yellow-200 to-pink-200' 
        : 'bg-gradient-to-br from-indigo-900 to-purple-900'
    }`}>
      {/* Quiz Bubbles */}
      {quizBubbles.map((bubble, index) => (
        <QuizBubble
          key={index}
          question={bubble.question}
          answer={bubble.answer}
          x={bubble.x}
          y={bubble.y}
          theme={theme}
        />
      ))}

      {/* Floating Game Elements */}
      <div className="absolute inset-0 pointer-events-none">
        {Array.from({ length: 20 }).map((_, i) => (
          <motion.div
            key={i}
            className={`absolute ${
              theme === 'light' ? 'text-pink-400' : 'text-yellow-400'
            }`}
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              fontSize: `${Math.random() * 20 + 10}px`,
            }}
            animate={{
              y: [0, -10, 0],
              rotate: [0, 360],
            }}
            transition={{
              duration: Math.random() * 2 + 3,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          >
            {['🎲', '🧩', '🏆', '🎯', '❓'][Math.floor(Math.random() * 5)]}
          </motion.div>
        ))}
      </div>

      <div className="container mx-auto px-4 py-12 relative z-10">
        <motion.div 
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 100 }}
          className={`max-w-2xl mx-auto rounded-3xl overflow-hidden shadow-2xl ${
            theme === 'light' 
              ? 'bg-white bg-opacity-80' 
              : 'bg-gray-800 bg-opacity-80'
          }`}
          style={{ backdropFilter: 'blur(10px)' }}
        >
          {/* Header */}
          <div className={`px-8 py-6 ${
            theme === 'light' 
              ? 'bg-gradient-to-r from-yellow-400 to-pink-500' 
              : 'bg-gradient-to-r from-indigo-600 to-purple-700'
          }`}>
            <h1 className="text-4xl font-bold text-white flex items-center justify-between">
              <span className="flex items-center">
                <Gamepad2 className="mr-3 h-8 w-8" />
                Quiz Master Settings
              </span>
              <motion.button
                onClick={toggleTheme}
                whileHover={{ scale: 1.1, rotate: 180 }}
                whileTap={{ scale: 0.9 }}
                className={`p-2 rounded-full ${
                  theme === 'light' 
                    ? 'bg-indigo-600 text-white' 
                    : 'bg-yellow-400 text-gray-900'
                }`}
              >
                {theme === 'light' ? <Moon className="h-6 w-6" /> : <Sun className="h-6 w-6" />}
              </motion.button>
            </h1>
          </div>

          {/* Content */}
          <div className="p-8 space-y-8">
            <AnimatePresence>
              {message.content && (
                <motion.div 
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className={`p-4 rounded-lg ${
                    message.type === 'success' 
                      ? 'bg-green-100 text-green-700 border border-green-200' 
                      : 'bg-red-100 text-red-700 border border-red-200'
                  }`}
                >
                  {message.content}
                </motion.div>
              )}
            </AnimatePresence>

            {/* Username Update Section */}
            <motion.div 
              className="space-y-4"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
            >
              <motion.button
                onClick={() => setUpdateType(updateType === 'username' ? null : 'username')}
                className={`flex items-center justify-between w-full text-xl font-medium ${
                  theme === 'light' 
                    ? 'text-pink-600 hover:text-pink-700' 
                    : 'text-pink-300 hover:text-pink-200'
                } transition-colors`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span className="flex items-center">
                  <User className="h-6 w-6 mr-3" />
                  Update Quiz Master Name 🧠
                </span>
                <Trophy className={`h-6 w-6 ${updateType === 'username' ? 'text-yellow-400' : ''}`} />
              </motion.button>
              
              <AnimatePresence>
                {updateType === 'username' && (
                  <motion.form 
                    onSubmit={handleUpdate} 
                    className="space-y-4"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <motion.div
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.1 }}
                    >
                      <input
                        type="text"
                        placeholder="New Quiz Master Name"
                        value={newUsername}
                        onChange={(e) => setNewUsername(e.target.value)}
                        className={`w-full p-3 border-2 rounded-lg focus:ring-2 focus:ring-pink-500 focus:border-transparent transition-all ${
                          theme === 'light' 
                            ? 'bg-white bg-opacity-50 border-pink-200' 
                            : 'bg-gray-700 border-pink-700 text-white'
                        }`}
                      />
                    </motion.div>
                    <motion.button
                      type="submit"
                      className={`flex items-center justify-center w-full gap-2 px-4 py-3 rounded-lg text-white font-medium transition-colors ${
                        theme === 'light' 
                          ? 'bg-pink-500 hover:bg-pink-600' 
                          : 'bg-pink-700 hover:bg-pink-800'
                      }`}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <Save className="h-5 w-5" />
                      Save New Name
                    </motion.button>
                  </motion.form>
                )}
              </AnimatePresence>
            </motion.div>

            {/* Password Update Section */}
            <motion.div 
              className="space-y-4 pt-4 border-t border-pink-200"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 }}
            >
              <motion.button
                onClick={() => setUpdateType(updateType === 'password' ? null : 'password')}
                className={`flex items-center justify-between w-full text-xl font-medium ${
                  theme === 'light' 
                    ? 'text-purple-600 hover:text-purple-700' 
                    : 'text-purple-300 hover:text-purple-200'
                } transition-colors`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span className="flex items-center">
                  <Lock className="h-6 w-6 mr-3" />
                  Change Secret Code 🔒
                </span>
                <Puzzle className={`h-6 w-6 ${updateType === 'password' ? 'text-blue-400' : ''}`} />
              </motion.button>
              
              <AnimatePresence>
                {updateType === 'password' && (
                  <motion.form 
                    onSubmit={handleUpdate} 
                    className="space-y-4"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {[
                      { placeholder: 'Current Secret Code', value: currentPassword, setValue: setCurrentPassword },
                      { placeholder: 'New Secret Code', value: newPassword, setValue: setNewPassword },
                      { placeholder: 'Confirm New Secret Code', value: confirmPassword, setValue: setConfirmPassword }
                    ].map((field, index) => (
                      <motion.div
                        key={field.placeholder}
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.1 * (index + 1) }}
                      >
                        <input
                          type="password"
                          placeholder={field.placeholder}
                          value={field.value}
                          onChange={(e) => field.setValue(e.target.value)}
                          className={`w-full p-3 border-2 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all ${
                            theme === 'light' 
                              ? 'bg-white bg-opacity-50 border-purple-200' 
                              : 'bg-gray-700 border-purple-700 text-white'
                          }`}
                        />
                      </motion.div>
                    ))}
                    <motion.button
                      type="submit"
                      className={`flex items-center justify-center w-full gap-2 px-4 py-3 rounded-lg text-white font-medium transition-colors ${
                        theme === 'light' 
                          ? 'bg-purple-500 hover:bg-purple-600' 
                          : 'bg-purple-700 hover:bg-purple-800'
                      }`}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <Save className="h-5 w-5" />
                      Secure Secret Code
                    </motion.button>
                  </motion.form>
                )}
              </AnimatePresence>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default QuizGameSettingsPage;

