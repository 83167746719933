import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Download, ChevronRight, Calendar, Filter, ArrowUp, ArrowDown, RefreshCw, X, ChevronLeft } from 'lucide-react';
import api from '../config/axious';
import { motion, AnimatePresence } from 'framer-motion';
import confetti from 'canvas-confetti';

// Add styles for text shadow
const styles = {
  textShadow: {
    textShadow: '0 1px 2px rgba(0, 0, 0, 0.2)'
  }
};

const Participants = () => {
  const navigate = useNavigate();
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [userId, setUserId] = useState('');
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [filterPeriod, setFilterPeriod] = useState('all');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [showConfetti, setShowConfetti] = useState(false);
  const [isGameModalOpen, setIsGameModalOpen] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [participantsPerPage] = useState(10);

  // Water Jug Game state
  const [jug1, setJug1] = useState(0);
  const [jug2, setJug2] = useState(0);
  const [moves, setMoves] = useState(0);
  const [gameMessage, setGameMessage] = useState('');

  const capacity1 = 3;
  const capacity2 = 5;
  const target = 4;

  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };

  const fetchParticipants = async () => {
    try {
      const response = await api(`/api/participants/${userId}`)
      const data = response.data
      console.log(data)
      
      if (data.success) {
        setParticipants(data.participants)
      } else {
        setError(data.message)
      }
    } catch (err) {
      console.error('Error:', err)
      setError('Failed to fetch participants')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const ls = JSON.parse(localStorage.getItem('user'))
    if (ls?.id) {
      setUserId(ls.id)
    } else {
      setLoading(false)
      setError('Please login to view participants')
    }
  }, [])

  useEffect(() => {
    if (userId) {
      fetchParticipants()
    }
  }, [userId])

  useEffect(() => {
    if (jug1 === target || jug2 === target) {
      setGameMessage('Congratulations! You solved the puzzle!');
    } else {
      setGameMessage('');
    }
  }, [jug1, jug2]);

  const filteredParticipants = useMemo(() => {
    return participants.filter(participant => {
      const matchesSearch =  
        participant.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        participant.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        participant.quiz_title?.toLowerCase().includes(searchTerm.toLowerCase());

      if (filterPeriod === 'all') return matchesSearch

      const submissionDate = new Date(participant.submission_time)
      const currentDate = new Date()
      const timeDiff = currentDate.getTime() - submissionDate.getTime()
      const daysDiff = timeDiff / (1000 * 3600 * 24)

      if (filterPeriod === 'today') return daysDiff < 1 && matchesSearch
      if (filterPeriod === 'week') return daysDiff <= 7 && matchesSearch
      if (filterPeriod === 'month') return daysDiff <= 30 && matchesSearch

      return matchesSearch
    })
  }, [participants, searchTerm, filterPeriod])

  const sortedParticipants = useMemo(() => {
    let sortableItems = [...filteredParticipants]
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [filteredParticipants, sortConfig])

  const requestSort = (key) => {
    let direction = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  const exportToCSV = () => {
    const headers = ['Name', 'Email', 'Quiz', 'Score', 'Total Questions', 'Submission Date']
    const data = sortedParticipants.map(p => [
      p.name,
      p.email,
      p.quiz_title,
      p.score,
      p.total_questions,
      new Date(p.submission_time).toLocaleDateString()
    ])

    const csvContent = [
      headers.join(','),
      ...data.map(row => row.join(','))
    ].join('\n')

    const blob = new Blob([csvContent], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'participants.csv'
    a.click()
    window.URL.revokeObjectURL(url)

    setShowConfetti(true)
    setTimeout(() => setShowConfetti(false), 3000)
  }

  const formatPerformance = (score, total) => {
    const percentage = (score / total) * 100;
    const display = `${score}/${total}`;
    const text = `${Math.round(percentage)}%`;
    return { percentage, display, text };
  };

  const getPerformanceColor = (percentage) => {
    if (percentage >= 90) {
      return 'bg-green-500';
    } else if (percentage >= 70) {
      return 'bg-yellow-500';
    } else {
      return 'bg-red-500';
    }
  };

  const PerformanceBar = ({ score, total, compact = false }) => {
    const { percentage, display, text } = formatPerformance(score, total)
    const barColor = getPerformanceColor(percentage)
    
    if (compact) {
      return (
        <div className="flex items-center gap-2">
          <div className="relative w-16 h-2 bg-gray-100 rounded-full overflow-hidden">
            <motion.div
              className={`absolute left-0 top-0 h-full ${barColor}`}
              initial={{ width: 0 }}
              animate={{ width: `${Math.min(percentage, 100)}%` }}
              transition={{ duration: 0.5 }}
            />
          </div>
          <span className="text-xs whitespace-nowrap">
            {text}
          </span>
        </div>
      )
    }
    
    return (
      <div className="flex items-center gap-3 min-w-[200px]">
        <div className="relative flex-1 h-2 bg-gray-100 rounded-full overflow-hidden">
          <motion.div
            className={`absolute left-0 top-0 h-full ${barColor}`}
            initial={{ width: 0 }}
            animate={{ width: `${Math.min(percentage, 100)}%` }}
            transition={{ duration: 0.5 }}
          />
          {percentage > 100 && (
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-purple-500/40 animate-pulse" />
          )}
        </div>
        <div className="flex items-center gap-1 whitespace-nowrap text-sm">
          <span className="font-medium">{display}</span>
          <span className={`${percentage > 100 ? 'text-purple-600' : 'text-gray-500'}`}>
            ({text})
          </span>
        </div>
      </div>
    )
  }

  const SortIcon = ({ column }) => {
    if (sortConfig.key === column) {
      return sortConfig.direction === 'ascending' ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />
    }
    return null
  }

  // Water Jug Game functions
  const fillJug1 = () => {
    setJug1(capacity1);
    setMoves(moves + 1);
  };

  const fillJug2 = () => {
    setJug2(capacity2);
    setMoves(moves + 1);
  };

  const emptyJug1 = () => {
    setJug1(0);
    setMoves(moves + 1);
  };

  const emptyJug2 = () => {
    setJug2(0);
    setMoves(moves + 1);
  };

  const pourJug1ToJug2 = () => {
    const amount = Math.min(jug1, capacity2 - jug2);
    setJug1(jug1 - amount);
    setJug2(jug2 + amount);
    setMoves(moves + 1);
  };

  const pourJug2ToJug1 = () => {
    const amount = Math.min(jug2, capacity1 - jug1);
    setJug2(jug2 - amount);
    setJug1(jug1 + amount);
    setMoves(moves + 1);
  };

  const resetGame = () => {
    setJug1(0);
    setJug2(0);
    setMoves(0);
    setGameMessage('');
  };

  // Pagination logic
  const indexOfLastParticipant = currentPage * participantsPerPage;
  const indexOfFirstParticipant = indexOfLastParticipant - participantsPerPage;
  const currentParticipants = sortedParticipants.slice(indexOfFirstParticipant, indexOfLastParticipant);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        >
          <RefreshCw className="w-12 h-12 text-blue-500" />
        </motion.div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="text-center text-red-500 p-4">
        {error}
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="w-full max-w-7xl mx-auto px-4 py-4">
        <button
          onClick={handleBackToDashboard}
          className="inline-flex items-center text-indigo-600 hover:text-indigo-700 transition-colors"
        >
          <ChevronLeft className="w-5 h-5" />
          <span>Back to Dashboard</span>
        </button>
      </div>
      <div className="max-w-7xl mx-auto p-3 sm:p-6 space-y-6 bg-gradient-to-br from-blue-50 to-indigo-100 rounded-lg shadow-lg">
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col sm:flex-row justify-between items-start gap-4"
        >
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-6">
            <div>
              <h1 className="text-2xl sm:text-[35px] p-1 font-bold text-blue-600 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-600">Participants</h1>
              {/* <p className="text-gray-600 mt-1 text-sm sm:text-base">Track and manage quiz participants</p> */}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={exportToCSV}
              className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors shadow-md hover:shadow-lg"
            >
              <Download className="w-5 h-5" />
              Export CSV
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsGameModalOpen(true)}
              className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors shadow-md hover:shadow-lg"
            >
              <span role="img" aria-label="water">💧</span>
              Water Jug Game
            </motion.button>
          </div>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="flex flex-col sm:flex-row gap-4"
        >
          <div className="relative flex-grow">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search participants..."
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white shadow-sm"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="relative">
            <select
              value={filterPeriod}
              onChange={(e) => setFilterPeriod(e.target.value)}
              className="appearance-none w-full sm:w-auto pl-10 pr-8 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white shadow-sm"
            >
              <option value="all">All Time</option>
              <option value="today">Today</option>
              <option value="week">This Week</option>
              <option value="month">This Month</option>
            </select>
            <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <Filter className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          </div>
        </motion.div>

        {/* Desktop Table View */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="hidden md:block overflow-x-auto bg-white rounded-lg shadow-md"
        >
          <table className="w-full">
            <thead>
              <tr className="text-left bg-gray-50">
                <th className="px-6 py-3 text-gray-600 font-medium cursor-pointer" onClick={() => requestSort('name')}>
                  <div className="flex items-center gap-2">
                    Name
                    <SortIcon column="name" />
                  </div>
                </th>
                <th className="px-6 py-3 text-gray-600 font-medium cursor-pointer" onClick={() => requestSort('email')}>
                  <div className="flex items-center gap-2">
                    Email
                    <SortIcon column="email" />
                  </div>
                </th>
                <th className="px-6 py-3 text-gray-600 font-medium cursor-pointer" onClick={() => requestSort('quiz_title')}>
                  <div className="flex items-center gap-2">
                    Quiz
                    <SortIcon column="quiz_title" />
                  </div>
                </th>
                <th className="px-6 py-3 text-gray-600 font-medium cursor-pointer" onClick={() => requestSort('score')}>
                  <div className="flex items-center gap-2">
                    Performance
                    <SortIcon column="score" />
                  </div>
                </th>
                <th className="px-6 py-3 text-gray-600 font-medium cursor-pointer" onClick={() => requestSort('total_questions')}>
                  <div className="flex items-center gap-2">
                    Questions
                    <SortIcon column="total_questions" />
                  </div>
                </th>
                <th className="px-6 py-3 text-gray-600 font-medium cursor-pointer" onClick={() => requestSort('submission_time')}>
                  <div className="flex items-center gap-2">
                    Submission Date
                    <SortIcon column="submission_time" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <AnimatePresence>
                {currentParticipants.length > 0 ? (
                  currentParticipants.map((participant) => (
                    <motion.tr 
                      key={participant.id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="cursor-pointer hover:bg-blue-50 transition-colors"
                      onClick={() => setSelectedParticipant(participant)}
                    >
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-2">
                          <span className="text-yellow-500">👑</span>
                          {participant.name}
                        </div>
                      </td>
                      <td className="px-6 py-4">{participant.email}</td>
                      <td className="px-6 py-4">
                        <span className="px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm">
                          {participant.quiz_title}
                        </span>
                      </td>
                      <td className="px-6 py-4">
                        <PerformanceBar 
                          score={participant.score} 
                          total={participant.total_questions} 
                        />
                      </td>
                      <td className="px-6 py-4">{participant.total_questions}</td>
                      <td className="px-6 py-4">
                        {new Date(participant.submission_time).toLocaleDateString()}
                      </td>
                    </motion.tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="px-6 py-8 text-center text-gray-500">
                      No participants found
                    </td>
                  </tr>
                )}
              </AnimatePresence>
            </tbody>
          </table>
        </motion.div>

        {/* Pagination */}
        <div className="mt-4 flex justify-center">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeft className="h-5 w-5" aria-hidden="true" />
            </button>
            {Array.from({ length: Math.ceil(sortedParticipants.length / participantsPerPage) }).map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                  currentPage === index + 1 ? 'text-blue-600 bg-blue-50' : 'text-gray-700 hover:bg-gray-50'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === Math.ceil(sortedParticipants.length / participantsPerPage)}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRight className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>

        {/* Mobile Card View */}
        <div className="md:hidden space-y-4">
          <AnimatePresence>
            {currentParticipants.length > 0 ? (
              currentParticipants.map((participant) => (
                <motion.div
                  key={participant.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="bg-white border border-gray-200 rounded-lg p-4 cursor-pointer hover:border-blue-500 transition-all shadow-sm hover:shadow-md"
                  onClick={() => setSelectedParticipant(participant)}
                >
                  <div className="flex items-start justify-between">
                    <div className="space-y-1">
                      <div className="flex items-center gap-2">
                        <span className="text-yellow-500">👑</span>
                        <span className="font-medium">{participant.name}</span>
                      </div>
                      <p className="text-sm text-gray-500">{participant.email}</p>
                    </div>
                    <ChevronRight className="w-5 h-5 text-gray-400" />
                  </div>
                  
                  <div className="mt-3 space-y-2">
                    <div className="flex items-center justify-between">
                      <span className="px-2 py-1 bg-blue-100 text-blue-600 rounded-full text-xs">
                        {participant.quiz_title}
                      </span>
                      <span className="text-sm text-gray-500">
                        {new Date(participant.submission_time).toLocaleDateString()}
                      </span>
                    </div>
                    
                    <div className="flex items-center justify-between gap-4">
                      <PerformanceBar 
                        score={participant.score} 
                        total={participant.total_questions}
                        compact
                      />
                      <span className="text-sm text-gray-500">
                        {participant.total_questions} Q
                      </span>
                    </div>
                  </div>
                </motion.div>
              ))
            ) : (
              <div className="text-center py-8 text-gray-500">
                No participants found
              </div>
            )}
          </AnimatePresence>
        </div>

        {/* Mobile Pagination */}
        <div className="mt-4 flex justify-center md:hidden">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeft className="h-5 w-5" aria-hidden="true" />
            </button>
            <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
              Page {currentPage} of {Math.ceil(sortedParticipants.length / participantsPerPage)}
            </span>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === Math.ceil(sortedParticipants.length / participantsPerPage)}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRight className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>

        {/* Responsive Modal for Participant Details */}
        <AnimatePresence>
          {selectedParticipant && (
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 z-50 flex items-end sm:items-center justify-center p-4"
            >
              <motion.div
                initial={{ y: "100%", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: "100%", opacity: 0 }}
                className="bg-white w-full max-w-lg rounded-t-lg sm:rounded-lg max-h-[90vh] overflow-y-auto"
              >
                <div className="sticky top-0 bg-white border-b p-4 flex justify-between items-center">
                  <motion.h2 
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600"
                  >
                    ✨ Participant Details
                  </motion.h2>
                  <motion.button
                    whileHover={{ scale: 1.1, rotate: 90 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setSelectedParticipant(null)}
                    className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  >
                    <X className="w-5 h-5"/>
                  </motion.button>
                </div>
                
                <div className="p-6 space-y-8">
                  <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="space-y-6"
                  >
                    <div className="space-y-4">
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="p-4 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl"
                      >
                        <label className="block text-gray-500 text-sm mb-1">
                          <span className="mr-2">👤</span> Name
                        </label>
                        <p className="text-gray-900 font-medium text-lg">{selectedParticipant.name}</p>
                      </motion.div>
                      
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="p-4 bg-gradient-to-r from-purple-50 to-pink-50 rounded-xl"
                      >
                        <label className="block text-gray-500 text-sm mb-1">
                          <span className="mr-2">📧</span> Email
                        </label>
                        <p className="text-gray-900 text-lg">{selectedParticipant.email}</p>
                      </motion.div>
                      
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="p-4 bg-gradient-to-r from-green-50 to-teal-50 rounded-xl"
                      >
                        <label className="block text-gray-500 text-sm mb-1">
                          <span className="mr-2">📝</span> Quiz
                        </label>
                        <p className="text-gray-900 text-lg">{selectedParticipant.quiz_title}</p>
                      </motion.div>
                    </div>
                    
                    <div className="space-y-4">
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="p-4 bg-gradient-to-r from-yellow-50 to-amber-50 rounded-xl"
                      >
                        <label className="block text-gray-500 text-sm mb-1">
                          <span className="mr-2">🎯</span> Performance
                        </label>
                        <PerformanceBar 
                          score={selectedParticipant.score} 
                          total={selectedParticipant.total_questions} 
                        />
                      </motion.div>
                      
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="p-4 bg-gradient-to-r from-orange-50 to-red-50 rounded-xl"
                      >
                        <label className="block text-gray-500 text-sm mb-1">
                          <span className="mr-2">❓</span> Questions
                        </label>
                        <p className="text-gray-900 text-lg">
                          <span className="font-bold text-green-600">{selectedParticipant.score}</span> / 
                          <span className="font-medium">{selectedParticipant.total_questions}</span> correct
                          <span className="ml-2">✨</span>
                        </p>
                      </motion.div>
                      
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="p-4 bg-gradient-to-r from-cyan-50 to-blue-50 rounded-xl"
                      >
                        <label className="block text-gray-500 text-sm mb-1">
                          <span className="mr-2">📅</span> Submission Date
                        </label>
                        <p className="text-gray-900 text-lg">
                          {new Date(selectedParticipant.submission_time).toLocaleDateString()}
                        </p>
                      </motion.div>
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Water Jug Game Modal */}
        <AnimatePresence>
          {isGameModalOpen && (
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white w-full max-w-lg rounded-lg max-h-[90vh] overflow-y-auto"
              >
                <div className="p-6 space-y-4">
                  <div className="flex justify-between items-center">
                    <h2 className="text-2xl font-bold text-blue-600">🌊 Water Jug Challenge</h2>
                    <button
                      onClick={() => setIsGameModalOpen(false)}
                      className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                    >
                      <X className="w-5 h-5"/>
                    </button>
                  </div>
                  <p className="text-gray-600">Try to get exactly {target} liters in either jug! 🎯</p>
                  <div className="flex justify-center gap-8 mb-4">
                    <motion.div 
                      className="flex flex-col items-center"
                      whileHover={{ scale: 1.02 }}
                    >
                      <div className="w-24 h-48 bg-gradient-to-b from-blue-100 to-blue-200 rounded-xl relative overflow-hidden border-2 border-blue-300">
                        <motion.div
                          className="absolute bottom-0 w-full bg-gradient-to-b from-blue-400 to-blue-500"
                          initial={{ height: 0 }}
                          animate={{ 
                            height: `${(jug1 / capacity1) * 100}%`,
                            transition: { type: "spring", bounce: 0.3 }
                          }}
                        >
                          <motion.div
                            className="absolute top-0 left-0 w-full h-2 bg-blue-300 opacity-30"
                            animate={{
                              y: [-2, 2, -2],
                              transition: { repeat: Infinity, duration: 1.5, ease: "easeInOut" }
                            }}
                          />
                        </motion.div>
                        <div className="absolute inset-0 flex items-center justify-center text-2xl font-bold text-white" style={styles.textShadow}>
                          {jug1}L
                        </div>
                      </div>
                      <p className="mt-2 font-medium text-blue-600">Jug 1</p>
                    </motion.div>

                    <motion.div
                      animate={{
                        x: [-5, 5, -5],
                        transition: { repeat: Infinity, duration: 2, ease: "easeInOut" }
                      }}
                      className="flex items-center text-blue-500 text-2xl"
                    >
                      ⇄
                    </motion.div>

                    <motion.div 
                      className="flex flex-col items-center"
                      whileHover={{ scale: 1.02 }}
                    >
                      <div className="w-24 h-48 bg-gradient-to-b from-indigo-100 to-indigo-200 rounded-xl relative overflow-hidden border-2 border-indigo-300">
                        <motion.div
                          className="absolute bottom-0 w-full bg-gradient-to-b from-indigo-400 to-indigo-500"
                          initial={{ height: 0 }}
                          animate={{ 
                            height: `${(jug2 / capacity2) * 100}%`,
                            transition: { type: "spring", bounce: 0.3 }
                          }}
                        >
                          <motion.div
                            className="absolute top-0 left-0 w-full h-2 bg-indigo-300 opacity-30"
                            animate={{
                              y: [-2, 2, -2],
                              transition: { repeat: Infinity, duration: 1.5, ease: "easeInOut" }
                            }}
                          />
                        </motion.div>
                        <div className="absolute inset-0 flex items-center justify-center text-2xl font-bold text-white" style={styles.textShadow}>
                          {jug2}L
                        </div>
                      </div>
                      <p className="mt-2 font-medium text-indigo-600">Jug 2</p>
                    </motion.div>
                  </div>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 mb-4">
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={fillJug1}
                      className="px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg hover:from-blue-600 hover:to-blue-700 transition-all"
                    >
                      Fill Jug 1
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={emptyJug1}
                      className="px-4 py-2 bg-gradient-to-r from-blue-400 to-blue-500 text-white rounded-lg hover:from-blue-500 hover:to-blue-600 transition-all"
                    >
                      Empty Jug 1
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={fillJug2}
                      className="px-4 py-2 bg-gradient-to-r from-indigo-500 to-indigo-600 text-white rounded-lg hover:from-indigo-600 hover:to-indigo-700 transition-all"
                    >
                      Fill Jug 2
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={emptyJug2}
                      className="px-4 py-2 bg-gradient-to-r from-indigo-400 to-indigo-500 text-white rounded-lg hover:from-indigo-500 hover:to-indigo-600 transition-all"
                    >
                      Empty Jug 2
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={pourJug1ToJug2}
                      className="px-4 py-2 bg-gradient-to-r from-blue-500 to-indigo-500 text-white rounded-lg hover:from-blue-600 hover:to-indigo-600 transition-all"
                    >
                      Pour 1 → 2
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={pourJug2ToJug1}
                      className="px-4 py-2 bg-gradient-to-r from-indigo-500 to-blue-500 text-white rounded-lg hover:from-indigo-600 hover:to-blue-600 transition-all"
                    >
                      Pour 2 → 1
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={resetGame}
                      className="px-4 py-2 bg-gradient-to-r from-red-500 to-red-600 text-white rounded-lg hover:from-red-600 hover:to-red-700 transition-all col-span-2 sm:col-span-3"
                    >
                      Reset Game
                    </motion.button>
                  </div>
                  <motion.p 
                    className="mb-2 text-lg font-semibold"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    key={moves}
                  >
                    Moves: {moves} 🚶
                  </motion.p>
                  {gameMessage && (
                    <motion.p 
                      className="text-green-600 font-bold text-xl"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                    >
                      {gameMessage} 🎉
                    </motion.p>
                  )}
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Participants;

