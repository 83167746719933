// import React, { useState, useEffect } from "react";
// import api from "../config/axious";
// import { useParams } from "react-router-dom";

// const PollParticipant = () => {
//   const [polls, setPolls] = useState([]);
//   const [socket, setSocket] = useState(null);
//   const [selectedOption, setSelectedOption] = useState({});
//   const [votedPolls, setVotedPolls] = useState({});
//   const [userEmail, setUserEmail] = useState("");
//   const [emailSubmitted, setEmailSubmitted] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const {shareToken}=useParams()


// useEffect(()=>{
//   fetchQuiz()
// },[])

// const fetchQuiz=async() =>{
// try{
// const fetchedPolls=await api.get('/api/get-polls/'+shareToken)
// setPolls(fetchedPolls.data.polls)
// }
// catch(error){
//   console.log(error)
// }
// }

//   const handleEmailSubmit = () => {
//     if (!userEmail || !userEmail.includes("@")) {
//       setErrorMessage("Please enter a valid email address.");
//       return;
//     }

//     api.post("/api/check-emails", { email: userEmail })
//      .then(response=>
//       {
//         if (response.data.success) {
//           if (response.data.alreadyVoted) {
//             setErrorMessage("You have already voted.");
//           } else {
//             setEmailSubmitted(true);
//           }
//         } else {
//           setErrorMessage(response.message || "An error occurred.");
//         }
      
//      })

//   };

//   const handleVote = (pollId) => {
//     if (!selectedOption[pollId]) {
//       setErrorMessage("Please select an option before submitting.");
//       return;
//     }
  
//     api.post("/api/submit-vote", {
//       pollId,
//       optionIndex: selectedOption[pollId],
//       email: userEmail, // Ensure this is the participant's email
//       shareToken
//     });
  
//     setVotedPolls((prev) => ({ ...prev, [pollId]: selectedOption[pollId] }));
//     console.log(votedPolls)
//     setErrorMessage("");
//   };
//   return (
//     <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
//       {!emailSubmitted && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
//             <h2 className="text-2xl font-bold text-center text-blue-600 mb-4">
//               Enter Your Email
//             </h2>
//             <input
//               type="email"
//               placeholder="Enter your email"
//               value={userEmail}
//               onChange={(e) => setUserEmail(e.target.value)}
//               className="w-full p-3 border rounded-lg mb-4"
//             />
//             {errorMessage && (
//               <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
//             )}
//             <button
//               onClick={handleEmailSubmit}
//               className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-300"
//             >
//               Submit
//             </button>
//           </div>
//         </div>
//       )}

//       <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
//         <h1 className="text-3xl font-bold text-center mb-6 text-blue-600">
//           Active Polls
//         </h1>

//         {polls.length > 0 ? (
//           polls.map((poll) => (
//             <div
//               key={poll.id}
//               className="mb-6 p-4 border rounded-lg bg-gray-50 shadow-sm"
//             >
//               <h2 className="text-xl font-semibold mb-4 text-gray-700">
//                 {poll.question}
//               </h2>
//               <ul className="space-y-2">
//                 {JSON.parse(poll.options).map((option, index) => (
//                   <li key={index}>
//                     <label
//                       className="flex items-center space-x-3 cursor-pointer"
//                       htmlFor={`poll-${poll.id}-option-${index}`}
//                     >
//                       <input
//                         type="radio"
//                         id={`poll-${poll.id}-option-${index}`}
//                         name={`poll-${poll.id}`}
//                         value={index}
//                         onChange={() =>
//                           setSelectedOption((prev) => ({
//                             ...prev,
//                             [poll.id]: index,
//                           }))
//                         }
//                         disabled={votedPolls[poll.id] !== undefined}
//                         className="form-radio h-5 w-5 text-blue-600"
//                       />
//                       <span
//                         className={`p-3 w-full rounded-lg ${
//                           votedPolls[poll.id] === index
//                             ? "bg-green-500 text-white"
//                             : "bg-gray-100 hover:bg-indigo-100"
//                         }`}
//                       >
//                         {option}
//                       </span>
//                     </label>
//                   </li>
//                 ))}
//               </ul>
//               {votedPolls[poll.id] !== undefined ? (
//                 <p className="mt-4 text-green-600 font-semibold">
//                   You voted for:{" "}
//                   {
//                     JSON.parse(poll.options)[votedPolls[poll.id]] // Display selected option
//                   }
//                 </p>
//               ) : (
//                 <button
//                   onClick={() => handleVote(poll.id)}
//                   className="mt-4 w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-300"
//                 >
//                   Submit Vote
//                 </button>
//               )}
//             </div>
//           ))
//         ) : (
//           <p className="text-gray-500 text-center">No active polls at the moment.</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default PollParticipant;



import React, { useState, useEffect } from "react";
import api from "../config/axious";
import { useParams } from "react-router-dom";
import { Vote, Mail, Check, AlertCircle, HelpCircle, Send, Radio } from "lucide-react";

const PollParticipant = () => {
  const [polls, setPolls] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [votedPolls, setVotedPolls] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { shareToken } = useParams();

  useEffect(() => {
    fetchQuiz();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setPolls((prevPolls) =>
        prevPolls.map((poll) => {
          const timeDifference = Math.max(0, new Date(poll.end_time) - new Date());
          const remainingTime = {
            days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((timeDifference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((timeDifference / (1000 * 60)) % 60),
            seconds: Math.floor((timeDifference / 1000) % 60),
          };
          return { ...poll, remainingTime };
        })
      );
    }, 1000);
  
    return () => {
      endQuiz();
      clearInterval(interval);
    } // Cleanup on unmount
  }, []);
  // const fetchQuiz = async () => {
  //   try {
  //     const fetchedPolls = await api.get('/api/get-polls/' + shareToken);
  //     setPolls(fetchedPolls.data.polls);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

useEffect(()=>{
console.log(polls)
},[polls])



const endQuiz=async() => {
try{
await api.post('/api/end-quiz'+shareToken)
}
catch(error){
  console.log("Error: " + error)
}
}


  const fetchQuiz = async () => {
    try {
      const fetchedPolls = await api.get('/api/get-polls/' + shareToken);
      console.log(fetchedPolls.data)
      const currentTime = new Date();
      const updatedPolls = fetchedPolls.data.polls.map((poll) => {
        const endTime = new Date(poll.end_time); // Assuming `end_time` is part of the API response
        const timeDifference = Math.max(0, endTime - currentTime); // Ensure non-negative time difference
        const remainingTime = {
          days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((timeDifference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((timeDifference / (1000 * 60)) % 60),
          seconds: Math.floor((timeDifference / 1000) % 60),
        };
        return { ...poll, remainingTime };
      });
  
      setPolls(updatedPolls);
    } catch (error) {
      console.log(error);
    }
  };

  


  const handleEmailSubmit = () => {
    if (!userEmail || !userEmail.includes("@")) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    api.post("/api/check-emails", { email: userEmail })
      .then(response => {
        if (response.data.success) {
          if (response.data.alreadyVoted) {
            setErrorMessage("You have already voted.");
          } else {
            setEmailSubmitted(true);
          }
        } else {
          setErrorMessage(response.message || "An error occurred.");
        }
      });
  };

  // const handleVote = (pollId) => {
  //   if (!selectedOption[pollId]) {
  //     setErrorMessage("Please select an option before submitting.");
  //     return;
  //   }

  //   api.post("/api/submit-vote", {
  //     pollId,
  //     optionIndex: selectedOption[pollId],
  //     email: userEmail,
  //     shareToken
  //   });

  //   setVotedPolls((prev) => ({ ...prev, [pollId]: selectedOption[pollId] }));
  //   setErrorMessage("");
  // };


  const handleVote = async (pollId) => {
    // Check if selectedOption[pollId] is 0 or exists
    if (selectedOption[pollId] === undefined) {
      setErrorMessage("Please select an option before submitting.");
      return;
    }

    try {
      const response = await api.post("/api/submit-vote", {
        pollId,
        optionIndex: selectedOption[pollId],
        email: userEmail,
        shareToken
      });

      if (response.data.success) {
        setVotedPolls((prev) => ({ ...prev, [pollId]: selectedOption[pollId] }));
        setErrorMessage("");
      } else {
        setErrorMessage(response.data.message || "Failed to submit vote. Please try again.");
      }
    } catch (error) {
      console.error("Vote submission error:", error);
      setErrorMessage("An error occurred while submitting your vote. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-purple-100 p-6">
      {!emailSubmitted && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm z-50">
          <div className="bg-white p-8 rounded-2xl shadow-2xl max-w-md w-full mx-4 border-t-4 border-indigo-500">
            <div className="flex items-center justify-center space-x-3 mb-6">
              <Mail className="w-8 h-8 text-indigo-600" />
              <h2 className="text-2xl font-bold text-gray-800">
                Welcome to the Poll
              </h2>
            </div>
            
            <p className="text-gray-600 mb-6 text-center">
              Please enter your email to participate in the poll
            </p>
            
            <div className="space-y-4">
              <div className="relative">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  className="w-full p-4 border-2 border-indigo-200 rounded-xl focus:ring-4 focus:ring-indigo-300 focus:border-indigo-500 transition-all duration-300 pl-12"
                />
                <Mail className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              </div>

              {errorMessage && (
                <div className="flex items-center space-x-2 text-red-500 bg-red-50 p-3 rounded-lg">
                  <AlertCircle className="w-5 h-5" />
                  <p className="text-sm">{errorMessage}</p>
                </div>
              )}

              <button
                onClick={handleEmailSubmit}
                className="w-full bg-indigo-600 text-white p-4 rounded-xl hover:bg-indigo-700 transition duration-300 flex items-center justify-center space-x-2 shadow-lg hover:shadow-xl"
              >
                <Send className="w-5 h-5" />
                <span>Start Voting</span>
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="max-w-4xl mx-auto space-y-6">
        <div className="bg-white rounded-2xl shadow-2xl p-8 text-center border-b-4 border-indigo-500">
          <div className="flex justify-center items-center space-x-3 mb-4">
            <Vote className="w-10 h-10 text-indigo-600" />
            <h1 className="text-3xl font-black bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-indigo-600">
              Active Polls
            </h1>
          </div>
          <p className="text-gray-600">Your voice matters! Cast your vote below</p>
        </div>

        {polls.length > 0 ? (
          <div className="space-y-6">
            {polls.map((poll) => (
             <div
             key={poll.id}
             className="bg-white rounded-2xl shadow-xl p-6 hover:shadow-2xl transition-all duration-300 border-l-4 border-indigo-500"
           >
             <div className="flex items-center space-x-3 mb-6">
               <HelpCircle className="w-6 h-6 text-indigo-600" />
               <h2 className="text-xl font-bold text-gray-800">{poll.question}</h2>
             </div>
         
             <div className="text-gray-600 mb-4">
               Time remaining: {poll.remainingTime.days}d {poll.remainingTime.hours}h {poll.remainingTime.minutes}m {poll.remainingTime.seconds}s
             </div>
                <div className="space-y-3">
                  {JSON.parse(poll.options).map((option, index) => (
                    <div key={index} className="relative">
                      <label
                        className={`flex items-center p-4 rounded-xl cursor-pointer transition-all duration-300 ${
                          selectedOption[poll.id] === index
                            ? 'bg-indigo-50 border-2 border-indigo-500'
                            : 'bg-gray-50 border-2 border-transparent hover:bg-indigo-50'
                        } ${votedPolls[poll.id] !== undefined ? 'cursor-not-allowed' : ''}`}
                      >
                        <input
                          type="radio"
                          name={`poll-${poll.id}`}
                          value={index}
                          checked={selectedOption[poll.id] === index}
                          onChange={() => setSelectedOption((prev) => ({
                            ...prev,
                            [poll.id]: index,
                          }))}
                          disabled={votedPolls[poll.id] !== undefined}
                          className="sr-only"
                        />
                        <div className={`w-6 h-6 rounded-full border-2 flex items-center justify-center mr-4 ${
                          selectedOption[poll.id] === index
                            ? 'border-indigo-500 bg-indigo-500'
                            : 'border-gray-300'
                        }`}>
                          {selectedOption[poll.id] === index && (
                            <Check className="w-4 h-4 text-white" />
                          )}
                        </div>
                        <span className="flex-1 text-gray-700">{option}</span>
                      </label>
                    </div>
                  ))}
                </div>

                {votedPolls[poll.id] !== undefined ? (
                  <div className="mt-6 flex items-center justify-center space-x-2 text-green-600 bg-green-50 p-4 rounded-xl">
                    <Check className="w-5 h-5" />
                    <p className="font-semibold">
                      Vote recorded: {JSON.parse(poll.options)[votedPolls[poll.id]]}
                    </p>
                  </div>
                ) : (
                  <button
                    onClick={() => handleVote(poll.id)}
                    className="mt-6 w-full bg-indigo-600 text-white p-4 rounded-xl hover:bg-indigo-700 transition-all duration-300 flex items-center justify-center space-x-2 shadow-lg hover:shadow-xl"
                  >
                    <Send className="w-5 h-5" />
                    <span>Submit Vote</span>
                  </button>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="bg-white rounded-2xl shadow-xl p-8 text-center">
            <HelpCircle className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500 text-lg">No active polls at the moment.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PollParticipant;




