import React, { useState, useEffect } from 'react';
import { Trophy, Medal, Crown, Sparkles, Star } from 'lucide-react';
import { useSpring, animated, config } from 'react-spring';
import api from "../config/axious";

const QuizLeaderboard = ({ quizId, currentUserEmail }) => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await api.get(`/api/quiz/${quizId}/leaderboard`);
        const data = response.data;

        const sortedData = data
          .sort((a, b) => {
            if (b.score !== a.score) {
              return b.score - a.score;
            }
            return new Date(a.submission_time) - new Date(b.submission_time);
          })
          .map((participant, index) => ({
            ...participant,
            rank: index + 1,
            medal: index < 3 ? ["gold", "silver", "bronze"][index] : null,
          }));

        setLeaderboardData(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch leaderboard:", error);
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, [quizId]);

  const fadeIn = useSpring({
    maxHeight: '80vh',
    opacity: loading ? 0 : 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const getRankIcon = (rank, medal) => {
    const iconClass = "transition-all duration-300 group-hover:scale-110";
    switch (medal) {
      case "gold":
        return <Crown className={`${iconClass} w-8 h-8 sm:w-10 sm:h-10 text-yellow-500`} />;
      case "silver":
        return <Medal className={`${iconClass} w-8 h-8 sm:w-10 sm:h-10 text-gray-400`} />;
      case "bronze":
        return <Trophy className={`${iconClass} w-8 h-8 sm:w-10 sm:h-10 text-amber-700`} />;
      default:
        return <span className="w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center font-bold text-gray-500">{rank}</span>;
    }
  };

  const getBackground = (medal, isCurrentUser) => {
    if (isCurrentUser) return "bg-gradient-to-r from-pink-100 to-purple-100 shadow-lg";
    switch (medal) {
      case "gold":
        return "bg-gradient-to-r from-yellow-100 to-amber-100";
      case "silver":
        return "bg-gradient-to-r from-gray-100 to-slate-100";
      case "bronze":
        return "bg-gradient-to-r from-orange-100 to-amber-100";
      default:
        return "bg-white hover:bg-gray-50";
    }
  };

  const WinnerPodium = ({ winner, index }) => {
    const springProps = useSpring({
      from: { transform: 'scale(0.8) translateY(20px)', opacity: 0 },
      to: { transform: 'scale(1) translateY(0)', opacity: 1 },
      delay: index * 200,
      config: config.wobbly,
    });

    return (
      <animated.div
        style={springProps}
        className={`flex flex-col items-center ${
          index === 0 ? 'order-2' : index === 1 ? 'order-1' : 'order-3'
        }`}
      >
        <div className={`
          ${getBackground(winner.medal, winner.p_email === currentUserEmail)}
          p-3 sm:p-5 rounded-xl w-28 sm:w-36 flex flex-col items-center gap-2 sm:gap-3
          transition-all duration-300 hover:shadow-xl relative overflow-hidden
        `}>
          {getRankIcon(winner.rank, winner.medal)}
          <div className="text-center z-10">
            <div className="font-bold text-sm sm:text-base truncate w-24 sm:w-28">{winner.p_name}</div>
            <div className="text-2xl sm:text-3xl font-black bg-gradient-to-r from-purple-600 to-pink-600 text-transparent bg-clip-text">
              {winner.score}
            </div>
          </div>
          {winner.medal && (
            <div className="absolute inset-0 opacity-20">
              {[...Array(20)].map((_, i) => (
                <Star key={i} className="absolute text-yellow-500 animate-twinkle" style={{
                  top: `${Math.random() * 100}%`,
                  left: `${Math.random() * 100}%`,
                  animationDelay: `${Math.random() * 5}s`,
                }} />
              ))}
            </div>
          )}
        </div>
        <div className="h-full bg-gradient-to-t from-gray-200 to-white w-full rounded-t-lg mt-2" style={{
          height: index === 0 ? '100%' : index === 1 ? '85%' : '70%'
        }} />
      </animated.div>
    );
  };

  const ParticipantItem = ({ participant, index }) => {
    const springProps = useSpring({
      from: { transform: 'translateX(-20px)', opacity: 0 },
      to: { transform: 'translateX(0)', opacity: 1 },
      delay: index * 100,
    });

    return (
      <animated.div
        style={springProps}
        className={`
          group flex items-center gap-3 sm:gap-4 p-3 sm:p-4 rounded-xl transition-all duration-300
          ${getBackground(participant.medal, participant.p_email === currentUserEmail)}
          hover:shadow-md
        `}
      >
        <div className="w-8 sm:w-10 text-center font-bold text-gray-600 text-sm sm:text-base">
          {participant.rank}
        </div>
        <div className="flex-1 min-w-0">
          <div className="font-semibold text-sm sm:text-base truncate">{participant.p_name}</div>
          <div className="text-xs sm:text-sm text-gray-500 truncate">{participant.p_email}</div>
        </div>
        <div className="text-lg sm:text-xl font-bold bg-gradient-to-r from-purple-600 to-pink-600 text-transparent bg-clip-text">
          {participant.score}
        </div>
      </animated.div>
    );
  };

  if (loading) {
    return (
      <div className="w-full max-w-3xl mx-auto bg-white rounded-xl shadow-lg p-4 sm:p-6">
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-4 border-pink-500 border-t-transparent"></div>
        </div>
      </div>
    );
  }

  if (leaderboardData.length === 0) {
    return (
      <div className="w-full max-w-3xl mx-auto bg-white rounded-xl shadow-lg p-4 sm:p-6">
        <div className="flex flex-col justify-center items-center h-64">
          <Trophy className="w-16 h-16 text-gray-300 mb-4" />
          <p className="text-gray-500 text-xl">No results available yet</p>
        </div>
      </div>
    );
  }

  return (
    <animated.div style={fadeIn} className="w-full max-w-3xl mx-auto bg-gradient-to-br from-purple-100 via-pink-100 to-red-100 backdrop-blur-sm rounded-xl shadow-lg overflow-hidden" >
      <div className="p-4 sm:p-6 flex flex-col h-full relative">
        <Sparkles className="absolute top-4 left-4 w-6 h-6 text-yellow-500 animate-pulse" />
        <Sparkles className="absolute top-4 right-4 w-6 h-6 text-yellow-500 animate-pulse" />
        <h2 className="text-3xl sm:text-4xl font-bold text-center mb-4 sm:mb-6 bg-gradient-to-r from-purple-600 to-pink-600 text-transparent bg-clip-text">
          🏆 Leaderboard Champions 🏆
        </h2>

        {/* Top 3 Winners Podium */}
        <div className="flex justify-center items-end gap-2 sm:gap-4 h-48 sm:h-56 mb-6 sm:mb-8 pt-4 sm:pt-8 relative">
          {leaderboardData.slice(0, 3).map((winner, index) => (
            <WinnerPodium key={winner.p_email} winner={winner} index={index} />
          ))}
        </div>

        {/* Other Participants */}
        <div className="space-y-2 sm:space-y-3 overflow-y-auto flex-grow" style={{ maxHeight: 'calc(80vh - 350px)' }}>
          {leaderboardData.slice(3).map((participant, index) => (
            <ParticipantItem key={participant.p_email} participant={participant} index={index} />
          ))}
        </div>

        <div className="text-center text-xs sm:text-sm text-gray-600 mt-4 sm:mt-6">
          Showing top {Math.min(10, leaderboardData.length)} participants 🎉
        </div>
      </div>
    </animated.div>
  );
};

export default QuizLeaderboard;

