// export default ActivePolls;
import React, { useEffect, useState } from "react";
import { Share2, BarChart, Zap, LucideView } from "lucide-react";
import api from "../config/axious";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const ActivePolls = ({refresh}) => {
  const [activePolls, setActivePolls] = useState([]);
  const [userId, setUserId] = useState("")
  const Navigate = useNavigate();

  useEffect(() => {
  const ls=JSON.parse(localStorage.getItem("user"))
  setUserId(ls.id)
if(userId){
  fetchActivePolls();

}
  }, [userId,refresh]);



  const fetchActivePolls = async () => {
    try {
      const response = await api.get(
        `/api/active-polls/${userId}`
      );

      if (response.data.success) {
        setActivePolls(response.data.data);
      } else {
        console.error("Failed to fetch active polls:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching active polls:", error.message);
    }
  };

  const copyToClipboard = (shareToken) => {
    const pollLink = `${window.location.origin}/pollingparticipent/${shareToken}`;
    navigator.clipboard.writeText(pollLink);
    toast.success("Link copied to clipboard")
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-3 mb-6">
        <Zap className="text-yellow-500 w-8 h-8" />
        <h2 className="text-3xl font-bold text-gray-800">
          Your Active Polls
        </h2>
      </div>

      {activePolls.length === 0 ? (
        <div className="bg-white rounded-2xl shadow-lg p-8 text-center">
          <p className="text-gray-500 text-lg">
            No active polls at the moment. Create your first poll!
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {activePolls.map((poll) => (
            <div
              key={poll.id}
              className="bg-white rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 p-6 border-l-4 border-indigo-500"
            >
              <h3 className="text-xl font-semibold mb-4 text-gray-800 line-clamp-2">
                {poll.question}
              </h3>
              
              <div className="flex justify-between space-x-3 mt-6">
                <button
                  onClick={() => copyToClipboard(poll.share_token)}
                  className="flex items-center space-x-2 text-blue-500 hover:text-blue-700 bg-blue-50 px-3 py-2 rounded-lg transition"
                >
                  <Share2 className="w-5 h-5" />
                  <span className="text-sm">Share</span>
                </button>
                
                <button
                  onClick={() => Navigate(`/livepollingresult/${poll.share_token}`)}
                  className="flex items-center space-x-2 text-green-500 hover:text-green-700 bg-green-50 px-3 py-2 rounded-lg transition"
                >
                  <LucideView  className="w-5 h-5" />
                  <span className="text-sm">Live Results</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActivePolls;