import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/layout/Navbar';
import Sidebar from '../components/layout/Sidebar';
import {
  FaRegQuestionCircle,
  FaClock,
  FaInfoCircle,
  FaTrophy,
  FaTag,
  FaExpandArrowsAlt,
  FaTimes,
  FaShare,
  FaChevronLeft,
  FaChevronRight
} from 'react-icons/fa';
import { FiCheckCircle, FiCopy } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import confetti from 'canvas-confetti';
import api from '../config/axious';

// Enhanced Media Preview with Modal Functionality
const MediaPreview = ({ media, questionIndex }) => {
  const [mediaType, setMediaType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (media) {
      const extension = media.split('.').pop().toLowerCase();
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
      const videoExtensions = ['mp4', 'webm', 'ogg'];

      if (imageExtensions.includes(extension)) {
        setMediaType('image');
      } else if (videoExtensions.includes(extension)) {
        setMediaType('video');
      }
    }
  }, [media]);

  if (!media) return null;

  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 10
        }}
        className="mt-6 rounded-lg overflow-hidden relative group"
      >
        {mediaType === 'image' && (
          <div className="relative">
            <motion.img
              src={media}
              alt={`Question ${questionIndex + 1} media`}
              whileHover={{ scale: 1.05 }}
              onClick={() => setIsModalOpen(true)}
              className="w-full h-64 object-cover rounded-lg shadow-lg cursor-pointer"
            />
            <motion.div 
              className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <FaExpandArrowsAlt
                className="text-white bg-black/50 rounded-full p-2"
                size={36}
              />
            </motion.div>
          </div>
        )}
        {mediaType === 'video' && (
          <motion.video
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            whileHover={{ scale: 1.02 }}
            controls
            className="w-full h-64 rounded-lg shadow-lg"
          >
            <source src={media} type={`video/${media.split('.').pop()}`} />
            Your browser does not support the video tag.
          </motion.video>
        )}
      </motion.div>

      <AnimatePresence>
        {isModalOpen && mediaType === 'image' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-8"
            onClick={() => setIsModalOpen(false)}
          >
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ type: "spring", stiffness: 300 }}
              className="max-w-5xl max-h-[90vh] relative"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={media}
                alt={`Question ${questionIndex + 1} media`}
                className="max-w-full max-h-full object-contain rounded-lg shadow-2xl"
              />
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setIsModalOpen(false)}
                className="absolute top-4 right-4 text-white bg-red-500/50 hover:bg-red-500/75 rounded-full p-2 transition-colors"
              >
                <FaTimes size={24} />
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

// Main Quiz Preview Component with Enhanced Functionality
const QuizPreview = () => {
  const { id } = useParams();
  const [quiz, setQuiz] = useState(null);
  const [shareLink, setShareLink] = useState('');
  const [userId,setUserId]=useState("")
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState('details');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const totalPoints = quiz?.questions?.reduce((sum, q) => sum + (q?.points || 1), 0) || 0;



  useEffect(()=>{

const ls=JSON.parse(localStorage.getItem('user'))
setUserId(ls.id)
console.log(userId)
  },[userId])

  useEffect(() => {
    const fetchQuizDetails = async () => {
      try {
        // Fetch quiz details
        const quizResponse = await api.get(`/api/quizzes/${id}`);
        const quizData = await quizResponse.data;

        if (quizData.success) {
          setQuiz(quizData);

          // Fetch share token
          const shareResponse = await api.get(`/api/quizzes/${id}/share-token`);
          const shareData = await shareResponse.data;
          
          if (shareData.success) {
            setShareLink(shareData.shareLink);
          }
        } else {
          setError('Failed to load quiz details.');
        }
      } catch (err) {
        console.error('Error fetching quiz details:', err);
        setError('Failed to load quiz details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchQuizDetails();
  }, [id]);

  const copyShareLink = () => {
    navigator.clipboard.writeText(shareLink+"/"+userId).then(() => {
      toast.success('Share link copied to clipboard!', {
        position: "bottom-right",
        autoClose: 3000,
      });
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
    }).catch(err => {
      console.error('Failed to copy link:', err);
      toast.error('Failed to copy link');
    });
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < quiz.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const prevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  if (loading)
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-200 via-blue-300 to-purple-400"
      >
        <motion.div
          animate={{
            scale: [1, 1.1, 1],
            rotate: [0, 5, -5, 0]
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          className="text-xl font-semibold text-gray-700 flex items-center space-x-4"
        >
          <div className="w-4 h-4 bg-indigo-600 rounded-full animate-bounce"></div>
          <div className="w-4 h-4 bg-indigo-600 rounded-full animate-bounce delay-100"></div>
          <div className="w-4 h-4 bg-indigo-600 rounded-full animate-bounce delay-200"></div>
        </motion.div>
      </motion.div>
    );

  if (error)
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-200 via-blue-300 to-purple-400"
      >
        <div className="text-red-600 font-medium text-lg bg-white/60 p-8 rounded-xl shadow-xl">
          {error}
        </div>
      </motion.div>
    );

  return (
<div className='bg-gradient-to-br from-indigo-100 via-blue-200 to-purple-300 min-h-screen flex justify-center items-center p-4 sm:p-6'>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 100 }}
            className="w-full md:max-w-xl mx-auto max-w-80 bg-white/60 backdrop-blur-md rounded-xl shadow-xl p-4 sm:p-8"
          >
            {/* Navigation Tabs */}
            <div className="flex justify-center mb-4 sm:mb-6 space-x-2 sm:space-x-4">
              {['details', 'questions'].map((section) => (
                <motion.button
                  key={section}
                  onClick={() => setActiveSection(section)}
                  className={`
                      px-3 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm font-semibold transition-all
                      ${activeSection === section
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }
                    `}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </motion.button>
              ))}
            </div>
            
            {/* Share Link Section */}
            {shareLink && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="mt-4 sm:mt-6 bg-indigo-50 p-3 sm:p-4s   rounded-lg flex flex-col flex-wrap sm:flex-row items-center justify-end space-y-4 sm:space-y-0 sm:space-x-3"
              >
                <div className="flex items-center space-x-2 sm:space-x-3 w-full  sm:w-auto overflow-hidden md:mb-3">
                  <FaShare className="text-indigo-600" size={20} />
                  <span className="text-sm sm:text-base text-gray-800 font-medium truncate max-w-full sm:max-w-md">
                    {shareLink+"/"+userId}
                  </span>
                </div>
                <motion.button
                  onClick={copyShareLink}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-indigo-600 text-white text-nowrap px-2 py-2 sm:px-4 sm:py-2 rounded-md flex items-center space-x-1 sm:space-x-2 hover:bg-indigo-700 transition text-xs sm:text-base"
                >
                  <FiCopy />
                  <span className='text-xs'>Copy Link</span>
                </motion.button>
              </motion.div>
            )}

            <AnimatePresence mode="wait">
              {activeSection === 'details' ? (
                <motion.div
                  key="details"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <motion.h1
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ type: "spring", stiffness: 150 }}
                    className="text-3xl sm:text-5xl font-extrabold text-indigo-700 text-center mb-4 sm:mb-6"
                  >
                    {quiz.quiz.title}
                  </motion.h1>

                  {quiz.quiz.description && (
                    <motion.p
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.2 }}
                      className="text-sm sm:text-lg text-gray-800 text-center italic mb-4 sm:mb-6"
                    >
                      {quiz.quiz.description}
                    </motion.p>
                  )}

                  <div className="flex flex-col sm:flex-row justify-between items-center mb-4 sm:mb-8 space-y-2 sm:space-y-0">
                    <motion.div
                      initial={{ x: -50, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      className="flex items-center space-x-2"
                    >
                      <FaClock className="text-indigo-700" size={20}  />
                      <p className="text-xs sm:text-base text-gray-700 font-semibold">
                        Time Limit: {quiz.quiz.time_limit} minutes
                      </p>
                    </motion.div>

                    <motion.div
                      initial={{ x: 50, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      className="flex items-center space-x-2 text-green-600"
                    >
                      <FaTrophy size={20}  />
                      <span className="text-xs sm:text-base font-semibold">Total Questions: {quiz.questions.length}</span>
                    </motion.div>
                  </div>

                  {/* Quiz Stats */}
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                    className="bg-white/80 rounded-lg p-4 sm:p-6 shadow-lg"
                  >
                    <h3 className="text-xl sm:text-2xl font-semibold text-indigo-700 mb-3 sm:mb-4">Quiz Statistics</h3>
                    <div className="grid grid-cols-2 gap-2 sm:gap-4">
                      <div className="flex items-center space-x-2">
                        <FaRegQuestionCircle className="text-indigo-600" size={16}  />
                        <span className="text-xs sm:text-base text-gray-700">Total Questions: {quiz.questions.length}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <FaTrophy className="text-green-600" size={16}  />
                        <span className="text-xs sm:text-base text-gray-700">Total Points: {totalPoints}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <FaClock className="text-orange-600" size={16} />
                        <span className="text-xs sm:text-base text-gray-700">Avg Time/Q: {Math.round(quiz.quiz.time_limit / quiz.questions.length)} min</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <FaInfoCircle className="text-blue-600" size={16} />
                        <span className="text-xs sm:text-base text-gray-700">Difficulty: {quiz.quiz.difficulty || 'Not specified'}</span>
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              ) : (
                <motion.div
                  key="questions"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <h2 className="text-2xl sm:text-3xl font-semibold text-gray-800 mb-3 sm:mb-4 flex flex-col sm:flex-row items-center justify-between">
                    <div className="flex items-center mb-2 sm:mb-0">
                      <FaRegQuestionCircle className="inline-block mr-2 text-indigo-700" size={20} />
                      Questions
                    </div>
                    <div className="text-base sm:text-lg text-green-600 flex items-center">
                      <FaTrophy className="mr-2" size={18} />
                      Total Points: {totalPoints}
                    </div>
                  </h2>

                  <AnimatePresence mode="wait">
                    <motion.div
                      key={currentQuestionIndex}
                      initial={{ opacity: 0, x: 50 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -50 }}
                      transition={{ type: "spring", stiffness: 100 }}
                      className="p-4 sm:p-8 bg-gradient-to-br from-white to-gray-50 rounded-lg shadow-lg mb-4 sm:mb-6"
                    >
                      <h3 className="text-lg sm:text-2xl font-semibold text-indigo-700 mb-3 sm:mb-4 flex items-center">
                        <FaTag className="mr-2" size={16} />
                        Question {currentQuestionIndex + 1}: <span className="text-gray-900 ml-2 text-sm sm:text-base">{quiz.questions[currentQuestionIndex].question_text}</span>
                      </h3>

                      {quiz.questions[currentQuestionIndex].options.length > 0 && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.2 }}
                          className="mt-2 sm:mt-4"
                        >
                          <p className="font-semibold text-gray-700 mb-1 sm:mb-2 text-sm sm:text-base">Options:</p>
                          <motion.ul
                            variants={{
                              hidden: { opacity: 0 },
                              show: {
                                opacity: 1,
                                transition: {
                                  staggerChildren: 0.1
                                }
                              }
                            }}
                            initial="hidden"
                            animate="show"
                            className="list-disc pl-4 sm:pl-5 space-y-1 sm:space-y-2 text-xs sm:text-base text-gray-700"
                          >
                            {quiz.questions[currentQuestionIndex].options.map((option, i) => (
                              <motion.li
                                key={i}
                                variants={{
                                  hidden: { opacity: 0, x: -20 },
                                  show: { opacity: 1, x: 0 }
                                }}
                                className="hover:text-indigo-600 flex items-center space-x-1 sm:space-x-2 cursor-pointer"
                              >
                                <FiCheckCircle className="text-green-500" size={14} />
                                <span>{option}</span>
                              </motion.li>
                            ))}
                          </motion.ul>
                        </motion.div>
                      )}

                      {quiz.questions[currentQuestionIndex].media && (
                        <MediaPreview 
                          media={quiz.questions[currentQuestionIndex].media} 
                          questionIndex={currentQuestionIndex} 
                        />
                      )}
                    </motion.div>
                  </AnimatePresence>

                  <div className="flex justify-between mt-4 sm:mt-6">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={prevQuestion}
                      disabled={currentQuestionIndex === 0}
                      className={`px-3 py-1 sm:px-4 sm:py-2 rounded-full flex items-center text-sm sm:text-base ${
                        currentQuestionIndex === 0
                          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          : 'bg-indigo-600 text-white hover:bg-indigo-700'
                      }`}
                    >
                      <FaChevronLeft className="mr-1 sm:mr-2" size={14} />
                      Previous
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={nextQuestion}
                      disabled={currentQuestionIndex === quiz.questions.length - 1}
                      className={`px-3 py-1 sm:px-4 sm:py-2 rounded-full flex items-center text-sm sm:text-base ${
                        currentQuestionIndex === quiz.questions.length - 1
                          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          : 'bg-indigo-600 text-white hover:bg-indigo-700'
                      }`}
                    >
                      Next
                      <FaChevronRight className="ml-1 sm:ml-2" size={14} />
                    </motion.button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
    </div>
    
       
  );
};

export default QuizPreview;

