import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';
import Swal from 'sweetalert2';

import Dashboard from './pages/Dashboard';
import CreateQuiz from './pages/CreateQuiz';
import EditQuiz from './pages/EditQuiz';
import QuizShareModal from './components/quiz/QuizShareModal';
import QuizPreview from './pages/QuizPreview';
import QuizParticipationPage from './pages/QuizParticipationPage'; 
import Results from './pages/Results';
import QuizAnalyticsDashboard from './pages/QuizAnalyticsDashboard';
import AppLayout from './components/layout/AppLayout';
import Auth from './components/common/Auth';
// import PollingApp from './pages/PollingApp';
import Participants from './pages/Participants';
import SettingsPage from './pages/SettingsPage';
import QuizLeaderboard from './pages/QuizLeaderboard';
import PollingApp from './pages/Polling';
import PollParticipant from './pages/PollParticipant';
import PollChart from './pages/LivePollingData';
import PollTable from './pages/Pollhistory';

// Add this style for custom toast styling
const ToastStyles = `
  .Toastify__toast-container {
    z-index: 9999;
  }
  .toast-body {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
  }
  .Toastify__toast {
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
`;
const toastCustomStyles = `
  .Toastify__close-button {
    color: white;
    opacity: 0.7;
    transition: opacity 0.3s ease;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 0 5px;
  }
  
  .Toastify__close-button:hover {
    opacity: 1;
  }
`;

// PrivateRoute component for authentication protection
const PrivateRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // Simulate checking auth status
    const checkAuth = async () => {
      try {
        setLoading(false);
      } catch (error) {
        console.error('Auth check failed:', error);
        setLoading(false);
      }
    };
    checkAuth();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return user ? children : <Navigate to="/" />;
};

// Main Routes component
const AppRoutes = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <Routes>
      {/* Public Route */}
      <Route
        path="/"
        element={user ? <Navigate to="/dashboard" /> : <Auth />}
      />
      <Route path="/quiz/:shareToken/:userId" element={<QuizParticipationPage />} />

      {/* Settings Route */}
      <Route
        path="/settings"
        element={
          <PrivateRoute>
            <AppLayout>
              <SettingsPage />
            </AppLayout>
          </PrivateRoute>
        }
      />

      {/* Polling Route */}
      <Route
        path="/polling"
        element={
          <PrivateRoute>
            <AppLayout>
              <PollingApp />
            </AppLayout>
          </PrivateRoute>
        }
      />

      {/* Other Private Routes */}
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <AppLayout>
              <Dashboard />
            </AppLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="/quiz/:quizId/participants"
        element={
          <PrivateRoute>
            <AppLayout>
              <Participants />
            </AppLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="/createquiz"
        element={
          <PrivateRoute>
            <AppLayout>
              <CreateQuiz />
            </AppLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="/edit-quiz/:quizId"
        element={
          <PrivateRoute>
            <AppLayout>
              <EditQuiz />
            </AppLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="/quiz_preview/:id"
        element={
          <PrivateRoute>
            <AppLayout>
              <QuizPreview />
            </AppLayout>
          </PrivateRoute>
        }
      />
      
      <Route
        path="/analytics"
        element={
          <PrivateRoute>
            <AppLayout>
              <QuizAnalyticsDashboard />
            </AppLayout>
          </PrivateRoute>
        }
      />

      <Route
        path="/results/:id"
        element={
          <PrivateRoute>
            <AppLayout>
              <Results />
            </AppLayout>
          </PrivateRoute>
        }
      />
 <Route
        path="/quiz/:quizId/leaderboard"
        element={
          <PrivateRoute>
            <AppLayout>
              <QuizLeaderboard />
            </AppLayout>
          </PrivateRoute>
        }
      />

<Route
    path="/polling"
    element={
      <PrivateRoute>
            <AppLayout>
    <PollingApp/>
    </AppLayout>
          </PrivateRoute>
    } />
      <Route
    path="/polling_history"
    element={
      <PrivateRoute>
            <AppLayout>
    <PollTable/>
    </AppLayout>
          </PrivateRoute>
    } />
      <Route
    path="/pollingparticipent/:shareToken"
    element={<PollParticipant/>} />

<Route
    path="/livepollingresult/:shareToken"
    element={
      <PrivateRoute>
            <AppLayout>
    <PollChart/>
    </AppLayout>
    </PrivateRoute>
    } />




      {/* Fallback Route */}
      <Route
        path="*"
        element={user ? <Navigate to="/dashboard" /> : <Navigate to="/" />}
      />
    </Routes>
  );
};

// Main App component
const App = () => {
  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <AppRoutes />
        
        {/* Add ToastContainer here */}
        <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        
        {/* Add custom styles */}
        <style>{ToastStyles}</style>
        <ToastContainer />
        <style>{toastCustomStyles}</style>
      </div>
    </Router>
  );
};

export default App;