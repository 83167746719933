import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, Plus, Sparkles, Save, X } from 'lucide-react';
import QuestionCard from '../components/quiz/QuestionCard';
import { motion, AnimatePresence } from 'framer-motion';
import api from '../config/axious';

const EditQuiz = () => {
  const navigate = useNavigate();
  const { quizId } = useParams();
  const [quiz, setQuiz] = useState({
    title: '',
    description: '',
    timeLimit: 30,
    questions: []
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch quiz details on component mount
  useEffect(() => {
    // alert(quizId)
    const fetchQuizDetails = async () => {
      try {
        const c_id = JSON.parse(localStorage.getItem("user"));
        const u_id = c_id.id;

        const response = await api.get(`/api/quizzes/${quizId}/edit`, {
          params: { userId: u_id }
        });

        if (response.data.success) {
          const { quiz } = response.data;
          setQuiz({
            title: quiz.title,
            description: quiz.description,
            timeLimit: quiz.timeLimit,
            questions: quiz.questions
          });
        } else {
          alert("Failed to load quiz details");
          navigate('/dashboard');
        }
      } catch (error) {
        console.error("Error fetching quiz details:", error);
        alert("An error occurred while fetching quiz details");
        navigate('/dashboard');
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuizDetails();
  }, [quizId, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const c_id = JSON.parse(localStorage.getItem("user"));
    const u_id = c_id.id;

    try {
      const formData = new FormData();

      formData.append("title", quiz.title);
      formData.append("description", quiz.description);
      formData.append("timeLimit", quiz.timeLimit);
      formData.append("userid", u_id);

      const questionsWithMediaInfo = quiz.questions.map((q) => ({
        text: q.text,
        type: q.type,
        options: q.options,
        correctAnswer: q.correctAnswer,
        points: q.points,
        media: q.mediaFile ? q.mediaFile.name : null,
      }));
      formData.append("questions", JSON.stringify(questionsWithMediaInfo));

      quiz.questions.forEach((question) => {
        if (question.mediaFile) {
          formData.append("media", question.mediaFile);
        }
      });

      const response = await api.put(`/api/quizzes/${quizId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const data = await response.data;

      if (data.success) {
        alert("Quiz updated successfully");
        navigate('/dashboard');
      } else {
        alert("Failed to update quiz: " + data.message);
      }
    } catch (error) {
      console.error("Error updating quiz:", error);
      alert("Failed to update quiz. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const addQuestion = () => {
    setQuiz(prev => ({
      ...prev,
      questions: [
        ...prev.questions,
        {
          text: '',
          type: 'multiple_choice',
          options: ['', ''],
          correctAnswer: '',
          points: 1,
          media: null
        }
      ]
    }));
  };

  // Loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-indigo-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <motion.button
        onClick={() => navigate(-1)}
        className="flex items-center gap-2 text-indigo-600 mb-6 hover:text-indigo-800 transition-colors duration-200"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <ArrowLeft className="h-5 w-5" />
        Back to Dashboard
      </motion.button>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-xl shadow-lg p-8 border border-indigo-100"
      >
        <h1 className="text-3xl font-bold text-indigo-900 mb-6 flex items-center">
          <Sparkles className="h-8 w-8 text-indigo-500 mr-2" />
          Edit Your Quiz
        </h1>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-6">
            <motion.div whileHover={{ scale: 1.02 }} transition={{ type: "spring", stiffness: 300 }}>
              <label className="block text-sm font-medium text-indigo-700 mb-2">
                Quiz Title
              </label>
              <input
                type="text"
                value={quiz.title}
                onChange={(e) => setQuiz({ ...quiz, title: e.target.value })}
                className="w-full px-4 py-2 border border-indigo-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                placeholder="Enter an updated quiz title"
                required
              />
            </motion.div>

            <motion.div whileHover={{ scale: 1.02 }} transition={{ type: "spring", stiffness: 300 }}>
              <label className="block text-sm font-medium text-indigo-700 mb-2">
                Description
              </label>
              <textarea
                value={quiz.description}
                onChange={(e) => setQuiz({ ...quiz, description: e.target.value })}
                className="w-full px-4 py-2 border border-indigo-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                rows={3}
                placeholder="Update quiz description"
                required
              />
            </motion.div>

            <motion.div whileHover={{ scale: 1.02 }} transition={{ type: "spring", stiffness: 300 }}>
              <label className="block text-sm font-medium text-indigo-700 mb-2">
                Time Limit (minutes)
              </label>
              <input
                type="number"
                value={quiz.timeLimit}
                onChange={(e) => setQuiz({ ...quiz, timeLimit: parseInt(e.target.value) })}
                className="w-full px-4 py-2 border border-indigo-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                min="1"
                required
              />
            </motion.div>

            <AnimatePresence>
              {quiz.questions.map((question, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <QuestionCard
                    question={question}
                    index={index}
                    onChange={(updatedQuestion) => {
                      const newQuestions = [...quiz.questions];
                      newQuestions[index] = updatedQuestion;
                      setQuiz({ ...quiz, questions: newQuestions });
                    }}
                    onDelete={() => {
                      const newQuestions = quiz.questions.filter((_, i) => i !== index);
                      setQuiz({ ...quiz, questions: newQuestions });
                    }}
                  />
                </motion.div>
              ))}
            </AnimatePresence>

            <motion.button
              type="button"
              onClick={addQuestion}
              className="w-full py-4 border-2 border-dashed border-indigo-300 rounded-lg text-indigo-600 hover:border-indigo-500 hover:text-indigo-700 hover:bg-indigo-50 transition-all duration-200 flex items-center justify-center gap-2"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <Plus className="h-5 w-5" />
              Add Magical Question
            </motion.button>

            <div className="flex justify-end gap-4 pt-6">
              <motion.button
                type="button"
                onClick={() => navigate('/dashboard')}
                className="px-6 py-2 border border-indigo-300 rounded-lg text-indigo-700 hover:bg-indigo-50 transition-all duration-200"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <X className="h-5 w-5 inline mr-2" />
                Cancel
              </motion.button>
              <motion.button
                type="submit"
                className="px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-200 flex items-center"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                disabled={isSubmitting}
              >
                <Save className="h-5 w-5 mr-2" />
                {isSubmitting ? 'Updating...' : 'Update Quiz'}
              </motion.button>
            </div>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default EditQuiz;