


import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QuizLeaderboard from './QuizLeaderboard';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'sonner';
import {
  Clock,
  CheckCircle2,
  XCircle,
  ArrowRight,
  ArrowLeft,
  Play,
  Send
} from 'lucide-react';
import api from '../config/axious';
import { EmailModal } from '../components/common/EmailModal';

const QuizParticipationPage = () => {
  const { shareToken } = useParams();
  const { userId } = useParams();
  const [quiz, setQuiz] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [letPlay, setLetPlay] = useState(false);
  const [score, setScore] = useState(0);
  const [totalPossiblePoints, setTotalPossiblePoints] = useState(0);
  const [showLeaderboard, setShowLeaderboard] = useState(false);

  // Fetch quiz details on component mount
  useEffect(() => {
    const fetchQuizDetails = async () => {
      try {
        const response = await api.get(`/api/quiz/share/${shareToken}`);
        setQuiz(response.data.quiz);
        setTimeRemaining(response.data.quiz.time_limit * 60);

        // Fetch questions for the quiz
        const questionsResponse = await api.get(`/api/quizzes/${response.data.quiz.id}/questions`);
        const fetchedQuestions = questionsResponse.data.questions;
        setQuestions(fetchedQuestions);
        
        // Calculate total possible points
        const totalPoints = fetchedQuestions.reduce((sum, question) => 
          sum + (question.points || 1), 0);
        setTotalPossiblePoints(totalPoints);
        
        setLoading(false);
      } catch (err) {
        setError('Failed to load quiz. Please check the link and try again.');
        setLoading(false);
        toast.error('Quiz not found');
      }
    };

    fetchQuizDetails();
  }, [shareToken]);


  // Timer logic
  useEffect(() => {
    let timer;
    if (quizStarted && timeRemaining > 0 && !quizCompleted) {
      timer = setInterval(() => {
        setTimeRemaining(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            handleQuizSubmit();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [quizStarted, quizCompleted]);

  const handleStartQuiz = () => {
    setQuizStarted(true);
  };

  const handleAnswerSelect = (questionId, selectedAnswer) => {
    setUserAnswers(prev => ({
      ...prev,
      [questionId]: selectedAnswer
    }));
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
    }
  };

  const handleQuizSubmit = async () => {
    setQuizCompleted(true);
  
    // Calculate score with individual question points
    const scoredQuestions = questions.map(question => {
      const isCorrect = userAnswers[question.id] === question.correct_answer;
      const questionPoints = question.points || 1;
      const earnedPoints = isCorrect ? questionPoints : 0;
  
      return {
        questionId: question.id,
        isCorrect,
        points: questionPoints,
        scoredPoints: earnedPoints
      };
    });
  
    // Sum up the total score - Fixed by declaring the variable with let
    let calculatedScore = scoredQuestions.reduce((sum, q) => sum + q.scoredPoints, 0);
    setScore(calculatedScore);
  
    try {
      if (quiz && quiz.id) {
        // Submit the quiz results to the server
        await api.post('/api/quiz/submit', {
          quizId: quiz.id,
          email,
          name,
          shareToken,
          userAnswers,
          score: calculatedScore,
          totalQuestions: questions.length,
          scoredQuestions,
          createdby: userId
        });
  
        toast.success(`Quiz completed! Your score: ${calculatedScore}`);
      } else {
        toast.error('Failed to submit quiz: Quiz data not available');
      }
    } catch (err) {
      toast.error('Failed to submit quiz');
    }
  };


  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex justify-center items-center h-screen bg-gradient-to-br from-pink-100 to-purple-100"
      >
        <motion.div
          animate={{
            scale: [1, 1.1, 1],
            rotate: [0, 5, -5, 0]
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity
          }}
          className="bg-white p-8 rounded-3xl shadow-2xl text-center border-4 border-pink-200"
        >
          <div className="text-4xl font-bold text-pink-600 mb-4">🌟 Quiz Magic 🎉</div>
          <div className="text-gray-500">Sprinkling some quiz dust...</div>
        </motion.div>
      </motion.div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-rose-50 flex items-center justify-center">
        <div className="bg-white p-8 rounded-3xl shadow-xl text-center border-4 border-rose-200">
          <XCircle className="mx-auto text-rose-500 w-16 h-16 mb-4" />
          <h2 className="text-2xl font-bold text-rose-600 mb-4">Oops! Quiz Adventure Interrupted 🙊</h2>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  } 

  if (quizCompleted) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="min-h-screen bg-gradient-to-br from-pink-100 to-purple-100 flex items-center justify-center"
      >
        <div className="bg-white p-10 rounded-3xl shadow-2xl text-center max-w-2xl w-full border-4 border-pink-200">
          <CheckCircle2 className="mx-auto text-green-500 w-20 h-20 mb-6" />
          <h1 className="text-3xl font-bold mb-4 text-gray-800">Quiz Completed! 🎊</h1>
          <p className="text-xl mb-2 text-gray-600">Your Adorable Score:</p>
          <div className="text-4xl font-bold text-pink-600 mb-6">
          {score} / {totalPossiblePoints} 🌈
          </div>
          {!showLeaderboard ? (
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowLeaderboard(true)}
              className="bg-pink-500 text-white px-6 py-3 rounded-full hover:bg-pink-600 shadow-lg"
            >
              Peek at Results 👀
            </motion.button>
          ) : (
            <div className="mt-6">
              <QuizLeaderboard
                quizId={quiz.id}
                currentUserEmail={email}
              />
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowLeaderboard(false)}
                className="mt-4 bg-purple-500 text-white px-6 py-3 rounded-full hover:bg-purple-600 shadow-lg"
              >
                Hide Results 👋
              </motion.button>
            </div>
          )}
        </div>
      </motion.div>
    );
  }
  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="min-h-screen bg-gradient-to-br from-pink-100 to-purple-100 flex items-center justify-center p-4">
      <EmailModal
        token={shareToken}
        setemail={setEmail}
        setname={setName}
        shouldplay={setLetPlay}
      />

      {letPlay && (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-white w-full max-w-3xl rounded-3xl shadow-2xl overflow-hidden border-4 border-pink-200"
        >
          {/* Quiz Header with Cute Timer */}
          <div className="bg-gradient-to-r from-pink-500 to-purple-600 p-6 flex justify-between items-center">
            <h1 className="text-3xl font-bold text-white">🌈 {quiz.title}</h1>
            <motion.div
              animate={{
                scale: timeRemaining < 60 ? [1, 1.2, 1] : 1,
                color: timeRemaining < 60 ? '#FF0000' : '#FFFFFF'
              }}
              transition={{ duration: 0.5 }}
              className="flex items-center gap-2 text-white"
            >
              <Clock className="w-6 h-6" />
              <span className="font-semibold text-xl">
                {Math.floor(timeRemaining / 60)}:
                {(timeRemaining % 60).toString().padStart(2, '0')}
              </span>
            </motion.div>
          </div>

          <div className="p-8">
            {!quizStarted ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-center"
              >
                <h2 className="text-2xl mb-6 text-gray-700">✨ {quiz.description} ✨</h2>
                <div className="grid grid-cols-3 gap-4 mb-8">
                  <div className="bg-pink-100 p-4 rounded-2xl shadow-md">
                    <div className="text-3xl font-bold text-pink-600">{questions.length} 📝</div>
                    <div className="text-gray-600">Total Questions</div>
                  </div>
                  <div className="bg-purple-100 p-4 rounded-2xl shadow-md">
                    <div className="text-3xl font-bold text-purple-600">{quiz.time_limit} ⏰</div>
                    <div className="text-gray-600">Minutes</div>
                  </div>
                  <div className="bg-teal-100 p-4 rounded-2xl shadow-md">
                  <div className="text-3xl font-bold text-teal-600">{totalPossiblePoints} 🌟</div>
                    <div className="text-gray-600">Total Points</div>
                  </div>
                </div>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleStartQuiz}
                  className="bg-gradient-to-r from-pink-500 to-purple-600 text-white px-8 py-4 rounded-full hover:from-pink-600 hover:to-purple-700 flex items-center mx-auto gap-2 shadow-lg"
                >
                  <Play className="w-6 h-6" />
                  Let's Play! 🚀
                </motion.button>
              </motion.div>
            ) : (
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentQuestionIndex}
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -50 }}
                  transition={{ duration: 0.3 }}
                >
                  {/* Question Display */}
                  <div className="bg-pink-50 p-6 rounded-2xl mb-6 border-2 border-pink-200 shadow-md">
                    <div className="flex justify-between items-center mb-4">
                      <div className="flex items-center gap-4">
                        <h3 className="text-xl font-semibold">
                          Question {currentQuestionIndex + 1} of {questions.length} 🤔
                        </h3>
                        <span className="bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-sm">
                          Points: {currentQuestion.points || 1} ✨
                        </span>
                      </div>
                      {currentQuestion.media && (
                        <div className="bg-white p-2 rounded-2xl shadow-md">
                          {currentQuestion.media.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp)$/) ? (
                            <img
                              src={currentQuestion.media}
                              alt="Question media"
                              className="max-h-40 object-contain rounded-xl"
                            />
                          ) : currentQuestion.media.toLowerCase().match(/\.(mp4|webm|ogg)$/) ? (
                            <video
                              src={currentQuestion.media}
                              controls
                              className="max-h-40 object-contain rounded-xl"
                            >
                              Your browser does not support the video tag.
                            </video>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <p className="mb-4 text-lg">🌈 {currentQuestion.question_text}</p>

                    {/* Options with Cute Styling */}
                    <div className="space-y-3">
                      {currentQuestion.options.map((option, index) => (
                        <motion.button
                          key={index}
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                          onClick={() => handleAnswerSelect(currentQuestion.id, option)}
                          className={`w-full text-left p-3 border-2 rounded-2xl transition-all duration-300 
                            ${userAnswers[currentQuestion.id] === option
                              ? 'bg-pink-200 border-pink-500 text-pink-800 shadow-md'
                              : 'bg-white border-pink-200 hover:border-pink-400 hover:bg-pink-50'}`}
                        >
                          {option}
                        </motion.button>
                      ))}
                    </div>
                  </div>

                  {/* Navigation Buttons with Cute Styling */}
                  <div className="flex justify-between">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={handlePreviousQuestion}
                      disabled={currentQuestionIndex === 0}
                      className="bg-purple-200 text-purple-700 px-6 py-3 rounded-full disabled:opacity-50 flex items-center gap-2 shadow-md"
                    >
                      <ArrowLeft className="w-5 h-5" /> Previous 🔙
                    </motion.button>
                    {currentQuestionIndex === questions.length - 1 ? (
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={handleQuizSubmit}
                        className="bg-gradient-to-r from-pink-500 to-purple-500 text-white px-6 py-3 rounded-full hover:from-pink-600 hover:to-purple-600 flex items-center gap-2 shadow-lg"
                      >
                        Submit Quiz 🎉 <Send className="w-5 h-5" />
                      </motion.button>
                    ) : (
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={handleNextQuestion}
                        className="bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 flex items-center gap-2 shadow-lg"
                      >
                        Next 🚀 <ArrowRight className="w-5 h-5" />
                      </motion.button>
                    )}
                  </div>
                </motion.div>
              </AnimatePresence>
            )}
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default QuizParticipationPage;