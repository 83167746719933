import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Users, Clock, Trophy, FileText, Edit, Settings, HelpCircle, BarChart2, PieChart, Trash, Brain, Sparkles, GraduationCap, Award, Crown, Lightbulb, Zap, Target, Puzzle } from 'lucide-react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, PieChart as RechartsPieChart, Pie, Cell, Area, AreaChart
} from 'recharts';
import { motion, AnimatePresence } from 'framer-motion';
import api from '../config/axious';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const StatCard = ({ title, value, icon, trend, color }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className={`relative overflow-hidden bg-white rounded-2xl shadow-lg p-6 border-2 border-${color}-200`}
    >
      <div className={`absolute inset-0 opacity-10 bg-gradient-to-br from-${color}-400 to-${color}-600`}></div>
      <div className="relative flex justify-between items-start">
        <div className="space-y-2">
          <p className="text-sm font-medium text-gray-600">{title}</p>
          <p className={`text-3xl font-bold text-${color}-600`}>{value}</p>
          {trend && (
            <p className={`text-xs flex items-center ${trend.includes('+') ? 'text-green-500' : 'text-red-500'}`}>
              {trend.includes('+') ? '↑' : '↓'} {trend}
            </p>
          )}
        </div>
        <div className={`bg-${color}-100 p-4 rounded-full`}>
          {icon}
        </div>
      </div>
    </motion.div>
  );
};

const QuizCard = ({ quiz, onDelete }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleEdit = () => {
    navigate(`/edit-quiz/${quiz.id}`);
  };

  const handleDelete = async () => {
    const confirmDelete = await Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3B82F6',
      cancelButtonColor: '#EF4444',
      confirmButtonText: 'Yes, delete it!'
    });

    if (confirmDelete.isConfirmed) {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || !user.id) {
          throw new Error('User not authenticated');
        }
        const userId = user.id;

        const response = await api.delete(`/api/quizzes/${quiz.id}`, {
          params: { userId }
        });

        if (response.data.success) {
          toast.success('Quiz Deleted Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: 'linear-gradient(135deg, #10B981, #059669)',
              color: '#ffffff',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
              borderRadius: '8px',
              fontWeight: '500',
              padding: '12px 20px',
            }
          });

          onDelete(quiz.id);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || 'Failed to Delete Quiz', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'linear-gradient(135deg, #EF4444, #DC2626)',
            color: '#ffffff',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            borderRadius: '8px',
            fontWeight: '500',
            padding: '12px 20px',
          }
        });
      }
    }
  };

  return (
    <motion.div 
      whileHover={{ scale: 1.03 }}
      className={`bg-white rounded-2xl shadow-lg p-6 border-2 ${
        isHovered ? 'border-blue-400 shadow-xl' : 'border-blue-200'
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex justify-between items-start mb-4">
        <div className="flex items-center gap-2">
          <div className="bg-blue-100 p-2 rounded-full">
            <Puzzle className="text-blue-500" size={24} />
          </div>
          <h3 className="text-xl font-bold text-gray-800 truncate pr-4">{quiz.title}</h3>
        </div>
        <div className="flex space-x-2">
          <motion.button 
            whileHover={{ scale: 1.1 }}
            onClick={handleEdit} 
            className={`p-2 rounded-full transition-colors ${isHovered ? 'bg-yellow-100 text-yellow-600' : 'text-gray-400'}`}
          >
            <Edit size={18} />
          </motion.button>
          <motion.button 
            whileHover={{ scale: 1.1 }}
            onClick={() => navigate(`/quiz_preview/${quiz.id}`)} 
            className={`p-2 rounded-full transition-colors ${isHovered ? 'bg-green-100 text-green-600' : 'text-gray-400'}`}
          >
            <FileText size={18} />
          </motion.button>
        </div>
      </div>

      <p className="text-gray-600 mb-6 line-clamp-2 text-sm">{quiz.description}</p>

      <div className="grid grid-cols-3 gap-4 mb-6">
        <motion.div whileHover={{ scale: 1.05 }} className="bg-blue-50 rounded-xl p-3 text-center">
          <Clock className="mx-auto mb-1 text-blue-500" size={20} />
          <span className="text-sm font-medium text-gray-700">{quiz.timeLimit} min</span>
        </motion.div>
        <motion.div whileHover={{ scale: 1.05 }} className="bg-purple-50 rounded-xl p-3 text-center">
          <Users className="mx-auto mb-1 text-purple-500" size={20} />
          <span className="text-sm font-medium text-gray-700">{quiz.participants}</span>
        </motion.div>
        <motion.div whileHover={{ scale: 1.05 }} className="bg-yellow-50 rounded-xl p-3 text-center">
          <Trophy className="mx-auto mb-1 text-yellow-500" size={20} />
          <span className="text-sm font-medium text-gray-700">{quiz.avgScore}%</span>
        </motion.div>
      </div>

      <div className="flex justify-between items-center pt-4 border-t border-gray-200">
        <span className="text-xs text-gray-500">
          Created: {new Date(quiz.createdAt).toLocaleDateString()}
        </span>
        <motion.button 
          whileHover={{ scale: 1.1 }}
          onClick={handleDelete} 
          className={`p-2 rounded-full transition-colors ${isHovered ? 'bg-red-100 text-red-600' : 'text-gray-400'}`}
        >
          <Trash size={18} />
        </motion.button>
      </div>
    </motion.div>
  );
};

const EnhancedPerformanceChart = ({ quizzes }) => {
  const chartData = quizzes.map(quiz => ({
    name: quiz.title.length > 10 ? quiz.title.substring(0, 10) + '...' : quiz.title,
    'Avg Score': quiz.avgScore,
    'Participants': quiz.participants
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorScore" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#3B82F6" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorParticipants" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8B5CF6" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8B5CF6" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
        <XAxis dataKey="name" stroke="#6B7280" angle={-45} textAnchor="end" height={60} tick={{fontSize: 12}} />
        <YAxis stroke="#6B7280" tick={{fontSize: 12}} />
        <Tooltip
          contentStyle={{
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            border: 'none',
            fontSize: '12px'
          }}
        />
        <Legend wrapperStyle={{fontSize: '12px'}} />
        <Area type="monotone" dataKey="Avg Score" stroke="#3B82F6" fillOpacity={1} fill="url(#colorScore)" />
        <Area type="monotone" dataKey="Participants" stroke="#8B5CF6" fillOpacity={1} fill="url(#colorParticipants)" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const EnhancedQuizDistributionChart = ({ quizzes }) => {
  const COLORS = ['#3B82F6', '#8B5CF6', '#10B981', '#F59E0B', '#EF4444', '#EC4899'];

  const chartData = quizzes.map(quiz => ({
    name: quiz.title.length > 15 ? quiz.title.substring(0, 15) + '...' : quiz.title,
    value: quiz.participants
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <RechartsPieChart>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={90}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
          labelLine={{ stroke: '#555', strokeWidth: 1, strokeDasharray: '3 3' }}
        >
          {chartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
              className="hover:opacity-80 transition-opacity"
            />
          ))}
        </Pie>
        <Tooltip
          contentStyle={{
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            border: 'none',
            fontSize: '12px'
          }}
        />
        <Legend layout="vertical" align="right" verticalAlign="middle" wrapperStyle={{fontSize: '12px'}} />
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [quizzes, setQuizzes] = useState([]);
  const [stats, setStats] = useState({
    totalQuizzes: 0,
    totalParticipants: 0,
    averageScore: 0,
    completionRate: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('performance');
  const [showAllQuizzes, setShowAllQuizzes] = useState(false);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.id) {
        throw new Error('User not authenticated');
      }
      const userId = user.id;

      const quizzesResponse = await api.get(`/api/quizzes?userId=${userId}`);
      const quizzesData = await quizzesResponse.data;

      const statsResponse = await api.get(`/api/quizzes/stats?userId=${userId}`);
      const statsData = await statsResponse.data;

      if (quizzesData.success && statsData.success) {
        setQuizzes(quizzesData.quizzes);
        setStats(statsData.stats);
      } else {
        throw new Error('Failed to fetch dashboard data');
      }
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError(err.message || 'Failed to load dashboard data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-purple-100 to-pink-100 flex items-center justify-center">
        <motion.div 
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <motion.div 
            animate={{ rotate: 360 }}
            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
            className="mb-4"
          >
            <Zap className="w-16 h-16 text-yellow-500" />
          </motion.div>
          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="text-gray-700 text-lg animate-pulse font-semibold"
          >
            Loading your quiz adventure...
          </motion.p>
        </motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-purple-100 to-pink-100 flex items-center justify-center px-4">
        <motion.div 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white p-8 rounded-2xl shadow-lg text-center max-w-md w-full"
        >
          <motion.div 
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
            className="text-red-500 mb-4"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </motion.div>
          <h2 className="text-xl font-bold text-gray-800 mb-4">Oops! Something went wrong</h2>
          <p className="text-gray-600 mb-6">{error}</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => window.location.reload()}
            className="px-6 py-2 bg-blue-500 text-white rounded-xl hover:bg-blue-600 transition-colors text-sm font-semibold"
          >
            Try Again
          </motion.button>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-purple-100 to-pink-100">
      <div className="max-w-7xl mx-auto space-y-8 px-4 py-8">
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-2xl shadow-lg p-8 relative overflow-hidden"
        >
          <div className="absolute top-0 right-0 opacity-10">
            <Target className="w-64 h-64 text-blue-500" />
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between gap-6 relative z-10">
            <div className="flex-1">
              <div className="flex items-center gap-3 mb-2">
                <GraduationCap className="text-blue-500" size={32} />
                <h1 className="text-3xl font-bold text-gray-900">
                  Quiz Master Dashboard
                </h1>
              </div>
              <p className="text-gray-600">
                Create exciting quizzes and level up your knowledge game! 🚀
              </p>
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate('/createquiz')}
              className="bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white px-6 py-3 rounded-xl transition-all duration-300 flex items-center gap-2 shadow-lg hover:shadow-xl font-semibold"
            >
              <Plus size={20} />
              Create New Quiz
            </motion.button>
          </div>
        </motion.div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <StatCard 
            title="Total Quizzes" 
            value={stats.totalQuizzes} 
            icon={<Brain size={24} className="text-blue-500" />}
            trend="+12.5%"
            color="blue"
          />
          <StatCard 
            title="Active Students" 
            value={stats.totalParticipants} 
            icon={<Users size={24} className="text-purple-500" />}
            trend="+8.3%"
            color="purple"
          />
          <StatCard 
            title="Average Score" 
            value={`${stats.averageScore}%`} 
            icon={<Target size={24} className="text-yellow-500" />}
            trend="+5.2%"
            color="yellow"
          />
          <StatCard 
            title="Success Rate" 
            value={`${stats.completionRate}%`} 
            icon={<Award size={24} className="text-green-500" />}
            trend="+3.7%"
            color="green"
          />
        </div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="bg-white rounded-2xl shadow-lg p-8"
        >
          <div className="flex flex-col md:flex-row justify-between items-center mb-8">
            <div className="flex items-center gap-3">
              <Sparkles className="text-yellow-500" size={24} />
              <h2 className="text-2xl font-bold text-gray-900">Quiz Performance</h2>
            </div>
            <div className="flex space-x-2 bg-gray-100 rounded-xl p-1">
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setActiveTab('performance')}
                className={`px-4 py-2 rounded-lg transition-all duration-300 ${
                  activeTab === 'performance' 
                    ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white shadow-md' 
                    : 'text-gray-600 hover:bg-gray-200'
                }`}
              >
                Performance
              </motion.button>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setActiveTab('distribution')}
                className={`px-4 py-2 rounded-lg transition-all duration-300 ${
                  activeTab === 'distribution' 
                    ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white shadow-md' 
                    : 'text-gray-600 hover:bg-gray-200'
                }`}
              >
                Distribution
              </motion.button>
            </div>
          </div>
          
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              {activeTab === 'performance' ? (
                <EnhancedPerformanceChart quizzes={quizzes} />
              ) : (
                <EnhancedQuizDistributionChart quizzes={quizzes} />
              )}
            </motion.div>
          </AnimatePresence>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="bg-white rounded-2xl shadow-lg p-8"
        >
          <div className="flex flex-col md:flex-row justify-between items-center mb-8">
            <div className="flex items-center gap-3">
              <Puzzle className="text-blue-500" size={24} />
              <div>
                <h2 className="text-2xl font-bold text-gray-900">Your Quizzes</h2>
                <p className="text-gray-600">Create, manage, and analyze your quizzes</p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowAllQuizzes(!showAllQuizzes)}
                className="text-blue-500 hover:text-blue-600 transition-colors text-sm font-medium"
              >
                {showAllQuizzes ? 'Show Less' : 'Show All'}
              </motion.button>
              <motion.div whileHover={{ scale: 1.1 }}>
                <Settings className="text-gray-500 hover:text-blue-500 cursor-pointer transition-colors" size={20} />
              </motion.div>
              <motion.div whileHover={{ scale: 1.1 }}>
                <HelpCircle className="text-gray-500 hover:text-blue-500 cursor-pointer transition-colors" size={20} />
              </motion.div>
            </div>
          </div>
          
          <motion.div 
            layout
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          >
            <AnimatePresence>
              {(showAllQuizzes ? quizzes : quizzes.slice(0, 3)).map(quiz => (
                <motion.div
                  key={quiz.id}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.5 }}
                >
                  <QuizCard 
                    quiz={quiz} 
                    onDelete={(deletedQuizId) => {
                      setQuizzes(prevQuizzes => 
                        prevQuizzes.filter(quiz => quiz.id !== deletedQuizId)
                      );
                      fetchDashboardData();
                    }} 
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Dashboard;

