import React, { useEffect, useState } from 'react';
import { Search, ChevronLeft, ChevronRight, X } from 'lucide-react';
import api from '../config/axious';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const ResultModal = ({ isOpen, onClose, pollData }) => {
  if (!isOpen) return null;
  const options = JSON.parse(pollData?.options || '[]');
  const resultsData = pollData?.results ? JSON.parse(pollData.results).userVotes : [];
  
  const voteCounts = options.map(option => ({ option, voteCount: 0 }));
  resultsData.forEach(([_, vote]) => {
    if (voteCounts[vote]) {
      voteCounts[vote].voteCount++;
    }
  });
  const totalVotes = voteCounts.reduce((sum, item) => sum + item.voteCount, 0);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Poll Results</h2>
          <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded-full">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="p-4 sm:p-6">
          <div className="mb-4">
            <h3 className="font-medium text-lg mb-2">Question:</h3>
            <p className="text-gray-700 break-words">{pollData?.question}</p>
          </div>
          <div className="mb-4">
            <h3 className="font-medium text-lg mb-2">Options:</h3>
            <div className="space-y-2">
              {options.map((option, index) => {
                const voteCount = voteCounts[index]?.voteCount || 0;
                const percentage = totalVotes > 0 ? (voteCount / totalVotes) * 100 : 0;
                return (
                  <div key={index} className="bg-gray-50 p-3 rounded">
                    <div className="flex flex-wrap justify-between items-center mb-1 gap-2">
                      <span className="font-medium break-words">{option}</span>
                      <span className="text-blue-600 whitespace-nowrap">{voteCount} votes</span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: `${percentage}%` }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-4">
            <p className="text-gray-600">
              End Time: {moment(pollData?.end_time).format('DD-MM-YYYY HH:mm:ss')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const PollTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userID, setUserid] = useState("");
  const [selectedPoll, setSelectedPoll] = useState(null);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const filteredData = data.filter(item =>
    item.question.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleViewResults = (poll) => {
    setSelectedPoll(poll);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const ls = JSON.parse(localStorage.getItem("user"));
    setUserid(ls.id);
    if (userID) {
      fetchPollingData();
    }
  }, [userID]);

  const fetchPollingData = async () => {
    try {
      const response = await api.get(`/api/all-polls/${userID}`);
      setData(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="w-full p-2 sm:p-4 bg-white rounded-lg shadow">
        <div className='my-2'>
        <button className='py-3 px-7 rounded-md hover:shadow-lg bg-gray-100' onClick={()=>{navigate(-1)}}>
            Back
        </button>
        </div>
      <div className="mb-4 relative">
     
        <div className="relative">
          <input
            type="text"
            placeholder="Search polls..."
            className="w-full p-2 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Question
                  </th>
                  <th scope="col" className="hidden sm:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Options
                  </th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="hidden sm:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    End Time
                  </th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentItems.map((item) => (
                  <tr key={item.id} className="hover:bg-gray-50">
                    <td className="px-3 py-4">
                      <div className="text-sm text-gray-900 break-words">{item.question}</div>
                      <div className="sm:hidden mt-2">
                        <div className="text-sm text-gray-500 break-words">
                          {JSON.parse(item.options)?.join(', ')}
                        </div>
                        <div className="text-sm text-gray-500 mt-1">
                          {item.endTime}
                        </div>
                      </div>
                    </td>
                    <td className="hidden sm:table-cell px-3 py-4">
                      <div className="text-sm text-gray-500 break-words">
                        {JSON.parse(item.options)?.join(', ')}
                      </div>
                    </td>
                    <td className="px-3 py-4">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        item.status === 'completed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {item.status}
                      </span>
                    </td>
                    <td className="hidden sm:table-cell px-3 py-4">
                      <div className="text-sm text-gray-500">{moment(item.end_time).format("DD-MM-YYYY HH:MM:SS")}</div>
                    </td>
                    <td className="px-3 py-4">
                      {item.status === 'completed' ? (
                        <button 
                          onClick={() => handleViewResults(item)}
                          className="text-blue-600 hover:text-blue-900 font-medium text-sm"
                        >
                          View Results
                        </button>
                      ) : (
                        <span className="text-gray-500 text-sm">Ongoing</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row items-center justify-between gap-4 px-2 py-3 sm:px-6">
        <div className="flex flex-wrap justify-center gap-2">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`relative inline-flex items-center px-2 sm:px-4 py-2 text-sm font-medium rounded-md ${
              currentPage === 1
                ? 'text-gray-400 bg-gray-100 cursor-not-allowed'
                : 'text-gray-700 bg-white hover:bg-gray-50'
            }`}
          >
            <ChevronLeft className="w-5 h-5" />
            <span className="hidden sm:inline ml-1">Previous</span>
          </button>
          <div className="flex flex-wrap items-center gap-1">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
              <button
                key={number}
                onClick={() => handlePageChange(number)}
                className={`px-3 py-1 text-sm ${
                  currentPage === number
                    ? 'bg-blue-500 text-white rounded'
                    : 'text-gray-700 hover:bg-gray-100 rounded'
                }`}
              >
                {number}
              </button>
            ))}
          </div>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`relative inline-flex items-center px-2 sm:px-4 py-2 text-sm font-medium rounded-md ${
              currentPage === totalPages
                ? 'text-gray-400 bg-gray-100 cursor-not-allowed'
                : 'text-gray-700 bg-white hover:bg-gray-50'
            }`}
          >
            <span className="hidden sm:inline mr-1">Next</span>
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>

      <ResultModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        pollData={selectedPoll}
      />
    </div>
  );
};

export default PollTable;