import React, { useState, useEffect } from 'react';
import { Menu, X, Settings, User, Search, Sparkles } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ sideOpen = false, setSideOpen }) => {
  const navigate = useNavigate();
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [userName, setUserName] = useState('');
  
  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.user_name) {
        setUserName(user.user_name);
      }
    } catch (error) {
      console.error('Error getting user data:', error);
    }
  }, []);

  return (
    <nav className="fixed top-0 right-0 z-50 md:w-[calc(100%-256px)] w-full md:ml-64 transition-all duration-300">
      <div className="bg-[#1E293B] border-b border-gray-700/50 ">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center md:justify-end justify-between h-16">
            {/* Mobile Menu Button */}
            <div className="flex md:hidden ">
              <button
                onClick={() => setSideOpen(!sideOpen)}
                className="inline-flex items-center justify-center p-2 rounded-xl
                         text-gray-400 hover:text-white hover:bg-white/10
                         transition-colors duration-200"
              >
                {sideOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>
            </div>

            {/* Search Bar */}
            {/* <div className="flex-1 max-w-xl mx-4">
              <div className={`
                relative group
                ${isSearchFocused ? 'scale-[1.02]' : ''}
                transition-all duration-200
              `}>
                <input
                  type="text"
                  placeholder="Search quizzes..."
                  onFocus={() => setIsSearchFocused(true)}
                  onBlur={() => setIsSearchFocused(false)}
                  className="w-full bg-[#283548] 
                           text-gray-100
                           placeholder-gray-400
                           rounded-xl px-10 py-2
                           border border-gray-700
                           focus:border-emerald-400
                           focus:ring-4 focus:ring-emerald-400/10
                           transition-all duration-200"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400
                                group-hover:text-emerald-400 transition-colors duration-200" />
                <Sparkles className="absolute right-3 top-2.5 h-5 w-5 text-emerald-400 
                                   opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
              </div>
            </div> */}

            {/* Right Side Icons */}
            <div className=" flex items-end gap-4  ">
              {/* Settings Button */}
              <button 
                onClick={() => navigate('/settings')}
                className="p-2 text-gray-300 
                         hover:bg-white/10 rounded-xl
                         transition-all duration-200 group"
                aria-label="Settings"
              >
                <Settings className="h-6 w-6 transition-transform duration-200 
                                  group-hover:scale-110 group-hover:rotate-45" />
              </button>

              {/* User Profile */}
              <button className="flex items-center gap-3 p-1.5 pl-2 pr-4
                              text-gray-300
                              hover:bg-white/10 rounded-xl
                              transition-all duration-200 group">
                <div className="relative">
                  <div className="p-1 bg-gradient-to-r from-emerald-400 to-cyan-400 rounded-xl
                                transform transition-transform duration-200 group-hover:scale-105">
                    <User className="h-5 w-5 text-white" />
                  </div>
                </div>
                <span className="hidden sm:block text-sm font-medium">
                  {userName || 'Guest'}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;